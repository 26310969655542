/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Checkbox, Flex, FormLabel, Input, Text, useDisclosure, Wrap } from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form"
import { useParams, useNavigate } from "react-router-dom";
import { ShortcutContext } from "../../../context/ShortcutContext";
import { useGetKull, usePutKull, usePostKull, useKullEnums, useDeleteKull, getNewKullnrSync } from "../../../service/service";
import { Kull, KullHund } from "../../../types/Kull";
import { useKeyPress } from "../../../utils/hooks/KeyboardShortcutHooks";
import { CenterSpinner } from "../../Globals/CenterSpinner";
import { ConfirmationDialog } from "../../Globals/ConfirmationDialog";
import { KullEnumSelect } from "../EnumSelect";
import SearchableInput from "../SearchableInput";
import { AddableHundList } from "./AddableHundList";


const KullForm = () => {

    const { kull_nr } = useParams()
    const [kullNr, setKullNr] = useState((kull_nr) ? parseInt(kull_nr) : undefined)
    const { isLoading, isFetching, data: kullData } = useGetKull(kullNr, { enabled: !!kullNr })
    const { data: kullEnums } = useKullEnums()
    const { mutate: put } = usePutKull()
    const { mutate: post } = usePostKull()
    const navigate = useNavigate()
    const { dispatch } = useContext(ShortcutContext)
    const { mutate: del, isSuccess: successFullyDeleted } = useDeleteKull()
    const { isOpen, onOpen, onClose } = useDisclosure()

    const methods = useForm<Kull>()

    const multi = () => navigate("/")
    useKeyPress(["F10"], [], multi)
    useEffect(() => {
        dispatch({ contextId: "KullForm", id: "9_multi", shortcut: { keys: "F10", description: "MULTI", fn: multi } })
    }, [])

    const kullTabellNav = () => navigate("/kull_tabell")
    useKeyPress(["F1"], ["shift"], kullTabellNav)
    useEffect(() => {
        dispatch({ contextId: "KullForm", id: "1_kull_tabell", shortcut: { keys: "Sh-F1", description: "TABELL", fn: kullTabellNav } })
    }, [])

    const hent = () => {
        const kullNrValue = methods.getValues("kullnr")
        if (kullNrValue && kullNrValue > 0) {
            setKullNr(kullNrValue)
        }
    }
    useKeyPress(["F5"], [], hent)
    useEffect(() => {
        dispatch({ contextId: "KullForm", id: "6_hent_kull_nr", shortcut: { keys: "F5", description: "HENT FRA KULLNR", fn: hent } })
    }, [])

    const reg = () => post(methods.getValues())
    useKeyPress(["F2"], [], reg)
    useEffect(() => {
        dispatch({ contextId: "KullForm", id: "3_lagre", shortcut: { keys: "F2", description: "OK/REG", fn: reg } })
    }, [])

    const endre = () => put(methods.getValues())
    useKeyPress(["F8"], [], endre)
    useEffect(() => {
        dispatch({ contextId: "KullForm", id: "5_endre", shortcut: { keys: "F8", description: "ENDRE", fn: endre } })
    }, [])

    const slett = () => {
        const k = methods.getValues("kullnr")
        del(k.toString())
    }
    useKeyPress(["F7"], [], onOpen)
    useEffect(() => {
        dispatch({ contextId: "KullForm", id: "4_slette", shortcut: { keys: "F7", description: "SLETTE", fn: onOpen } })
    }, [])

    useEffect(() => {
        if (successFullyDeleted) {
            navigate("/kull_tabell")
        }
    }, [successFullyDeleted])

    useEffect(() => {
        if (!isLoading && !isFetching) {
            methods.reset({ ...kullData, fodt: kullData?.fodt?.split("T")[0] })
        }
    }, [isFetching])

    const setHunderValueFn = (hunder: KullHund[]) => {
        methods.setValue("hunder", hunder)
    }

    //TODO: Fylle ut oppdretter og adresse fra kennel
    //TODO: Fylle ut alt fra bokstav og kennel
    //TODO: Vis poststed fra postnummer

    return (
        <FormProvider {...methods}>
            {isFetching && <CenterSpinner />}
            <form>
                <Wrap>
                    <Flex>
                        <FormLabel>Kullbokstav</FormLabel>
                        <Input {...methods.register("kullb")} />
                    </Flex>
                    <Flex>
                        <FormLabel>Kennel</FormLabel>
                        <Input {...methods.register("kennel")} />
                    </Flex>
                    <Flex>
                        <FormLabel>Kullnr</FormLabel>
                        <SearchableInput useFormRegister={methods.register("kullnr")} onClick={hent} />
                    </Flex>
                    <Button marginLeft={2} width={"4xs"} onClick={() => getNewKullnrSync(true).then(data => methods.setValue("kullnr", data.next))}>Tysk kullnr</Button>
                    <Button marginLeft={2} width={"4xs"} onClick={() => getNewKullnrSync(false).then(data => methods.setValue("kullnr", data.next))}>Ikke-tysk kullnr</Button>
                </Wrap>
                <Wrap overflow={"visible"}>
                    <Flex>
                        <FormLabel>Fødselsdato</FormLabel>
                        <Input type="date" {...methods.register("fodt")} />
                    </Flex>
                    <Flex>
                        <FormLabel>FD-LAND</FormLabel>
                        <KullEnumSelect enumArray={kullEnums?.fd_land} name="fd_land_id" />
                    </Flex>
                </Wrap>
                <Wrap overflow={"visible"}>
                    <Flex>
                        <FormLabel>Oppdretter</FormLabel>
                        <Input {...methods.register("oppdr")} />
                    </Flex>
                </Wrap>
                <Wrap overflow={"visible"}>
                    <Flex>
                        <FormLabel>Adresse</FormLabel>
                        <Input {...methods.register("adresse")} />
                    </Flex>
                    <Flex>
                        <FormLabel>Postnr</FormLabel>
                        <Input {...methods.register("postnr")} />
                    </Flex>
                </Wrap>
                <Wrap>
                    <Flex>
                        <FormLabel>Farens Reg.nr</FormLabel>
                        <Input {...methods.register("farens_reg_nr")} />
                    </Flex>
                    <Flex>
                        <FormLabel>Navn</FormLabel>
                        <Input {...methods.register("farens_navn")} />
                    </Flex>
                    <Flex>
                        <FormLabel>Hd</FormLabel>
                        <Input disabled />
                    </Flex>
                </Wrap>
                <Wrap>
                    <Flex>
                        <FormLabel>Morens Reg.nr</FormLabel>
                        <Input {...methods.register("morens_reg_nr")} />
                    </Flex>
                    <Flex>
                        <FormLabel>Navn</FormLabel>
                        <Input {...methods.register("morens_navn")} />
                    </Flex>
                    <Flex>
                        <FormLabel>Hd</FormLabel>
                        <Input disabled />
                    </Flex>
                </Wrap>
                <Wrap>
                    <Flex>
                        <FormLabel>Antall hunder i kullet</FormLabel>
                        <Input type="number" {...methods.register("ant")} />
                    </Flex>
                    <Flex>
                        <FormLabel>Hanner</FormLabel>
                        <Input type="number" {...methods.register("h")} />
                    </Flex>
                    <Flex>
                        <FormLabel>Tisper</FormLabel>
                        <Input type="number" {...methods.register("t")} />
                    </Flex>
                </Wrap>
                <Wrap>
                    <Flex>
                        <FormLabel>Har du alle kullsøsknene?</FormLabel>
                        <Checkbox {...methods.register("alle")} />
                    </Flex>
                </Wrap>
                <Wrap>
                    <Flex width={"100%"}>
                        <Text>Kullet:</Text>
                        <Input {...methods.register("sosken")} />
                    </Flex>
                </Wrap>
                <Wrap>
                    <Flex width={"100%"}>
                        <Text>Innavl:</Text>
                        <Input {...methods.register("innavl")} />
                    </Flex>
                </Wrap>
                <AddableHundList hunder={methods.getValues("hunder")} setHunderValueFn={setHunderValueFn} />
            </form>
            <ConfirmationDialog onClose={onClose} isOpen={isOpen} onConfirm={slett} title={"Slett kull?"} description={"Dette kan ikke angres"} />
        </FormProvider>
    );
}

export default KullForm;
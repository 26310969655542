import { Wrap, Flex, FormLabel, Input, Button, Box, BoxProps } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { OppdretterFilter, useOppdretterEnums } from '../../../service/service'
import { OppdretterEnumSelect } from '../../Form/EnumSelect'

type FilterProps = { oppdretterFilter?: OppdretterFilter, setOppdretterFilter: React.Dispatch<React.SetStateAction<OppdretterFilter>> } & BoxProps
export default function OppdretterFilterSelect(props: FilterProps) {

    const { data: oppdretterEnums } = useOppdretterEnums()
    const methods = useForm<OppdretterFilter>({ defaultValues: props.oppdretterFilter })

    const onSubmit = (data: OppdretterFilter) => { console.log("Submitting!", data); props.setOppdretterFilter(data) }

    return (
        <Box {...props} overflow="visible">
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <Wrap overflow="visible">
                        <Flex>
                            <FormLabel width={20}>Kennelnavn</FormLabel>
                            <Input {...methods.register("kennel")} />
                        </Flex>
                        <Flex>
                            <FormLabel width={20}>Oppdretter</FormLabel>
                            <Input {...methods.register("oppdr")} />
                        </Flex>
                        <Flex>
                            <FormLabel width={20}>Land</FormLabel>
                            <OppdretterEnumSelect enumArray={oppdretterEnums?.fd_land} name="fd_land_id" />
                        </Flex>
                        <Button type="submit"> Søk </Button>
                    </Wrap>
                </form>
            </FormProvider>
        </Box>
    )
}

import { Box, Flex, FormLabel, Input, useToast, Wrap } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { PostStedFilter, useGetPoststed, useHunderEnums, usePoststedEnums, useSearchPoststed } from '../../../service/service'
import { getValueForEnumCode } from '../../../types/FormEnum'
import { Hund } from '../../../types/Hund'
import { HundEnumSelect } from '../EnumSelect'
import SearchableInput from '../SearchableInput'


type Props = {
    isCreate: boolean,
    getFromRegNr: () => void
}

export const DogMainInfoForm = (props: Props) => {

    const { register, getValues, setValue } = useFormContext<Hund>();
    const { isLoading, data: hunderEnums } = useHunderEnums()
    const { isLoading: postStedEnumsLoading, data: postStedEnums } = usePoststedEnums()
    const [postnrFilter, setPostnrFilter] = useState<string>()
    const { isLoading: poststedLoading, isFetching: poststedIsFetching, data: poststedData, isError, refetch } = useGetPoststed(postnrFilter, { enabled: false, refetchOnWindowFocus: false, retry: false })

    const toast = useToast()

    const getPostNr = () => getValues("postnr")

    const findByPostNr = () => {
        setPostnrFilter(getPostNr())
    }

    useEffect(() => {
        if (postnrFilter) {
            console.log("refetching!")
            refetch()
        } else if (postnrFilter === "") {
            setValue("poststed", undefined)
            setValue("fylke", getValueForEnumCode(postStedEnums?.fylke, undefined))
            setValue("land", getValueForEnumCode(postStedEnums?.land, undefined))
        }
    }, [postnrFilter])

    useEffect(() => {
        if (poststedIsFetching || postStedEnumsLoading) return

        if (isError) {
            toast({
                title: "Poststed ikke funnet",
                description: "Kunne ikke finne poststed med gitt postnr. Verdiene vil automatisk bli satt om du senere oppretter poststedet.",
                status: 'warning'
            })
            setValue("poststed", undefined)
            setValue("fylke", getValueForEnumCode(postStedEnums?.fylke, undefined))
            setValue("land", getValueForEnumCode(postStedEnums?.land, undefined))
        } else {
            const poststed = poststedData
            if (poststed) {
                setValue("poststed", poststed.sted)
                setValue("fylke", getValueForEnumCode(postStedEnums?.fylke, poststed.fylke_id))
                setValue("land", getValueForEnumCode(postStedEnums?.land, poststed.land_id))
            }
        }
    }, [poststedIsFetching, postStedEnumsLoading])

    return (
        <Box border={'2px solid black'}>
            <Wrap overflow={'visible'}>
                <Flex>
                    <FormLabel width={20}>REG.NR</FormLabel>
                    <SearchableInput isDisabled={!props.isCreate} placeholder="REG_NR" useFormRegister={register("reg_nr")} onClick={props.getFromRegNr} />
                </Flex>
                <Flex>
                    <FormLabel>FD-DATO</FormLabel>
                    <Input placeholder="FD-DATO" type="date" {...register("fodt")} />
                </Flex>
                <Flex>
                    <FormLabel>FD-LAND</FormLabel>
                    <HundEnumSelect enumArray={hunderEnums?.fd_land} name="fd_land_id" />
                </Flex>
                <Flex>
                    <FormLabel>KJØNN</FormLabel>
                    <HundEnumSelect enumArray={hunderEnums?.kjonn} name="kjonn_id" />
                </Flex>
                <Flex>
                    <FormLabel>KULLNR</FormLabel>
                    <Input placeholder="KULLNR" {...register("kullnr")} />
                </Flex>
            </Wrap>
            <Wrap overflow={'visible'}>
                <Flex>
                    <FormLabel width={20}>NAVN</FormLabel>
                    <Input placeholder="NAVN" {...register("navn")} />
                </Flex>
                <Flex>
                    <FormLabel>HD</FormLabel>
                    <HundEnumSelect enumArray={hunderEnums?.hd} name="hd_id" />
                </Flex>
                <Flex>
                    <FormLabel>AD</FormLabel>
                    <HundEnumSelect enumArray={hunderEnums?.ad} name="ad_id" />
                </Flex>
            </Wrap>
            <Wrap overflow={'visible'}>
                <Flex>
                    <FormLabel width={20}>KENNEL</FormLabel>
                    <Input placeholder="KENNEL" {...register("kennel")} />
                </Flex>
                <Flex>
                    <FormLabel>HEMOFILI</FormLabel>
                    <HundEnumSelect enumArray={hunderEnums?.hem} name="hem_id" />
                </Flex>
                <Flex>
                    <FormLabel>FARGE</FormLabel>
                    <Input placeholder="FARGE" {...register("farge")} />
                </Flex>
            </Wrap>
            <Wrap overflow={'visible'}>
                <Flex>
                    <FormLabel width={20}>EIER</FormLabel>
                    <Input maxLength={128} placeholder="EIER" {...register("eier")} />
                </Flex>
                <Flex>
                    <FormLabel>STED</FormLabel>
                    <Input maxLength={128} placeholder="STED" {...register("sted")} />
                </Flex>
                <Flex>
                    <FormLabel>POSTNR</FormLabel>
                    <SearchableInput maxLength={16} placeholder="POSTNR" useFormRegister={register("postnr")} onClick={findByPostNr} />
                </Flex>
                <Flex>
                    <FormLabel width={20}>POSTSTED</FormLabel>
                    <Input disabled placeholder="POSTSTED" {...register("poststed")} />
                </Flex>
                <Flex>
                    <FormLabel>FYLKE</FormLabel>
                    <Input disabled placeholder="FYLKE" {...register("fylke")} />
                </Flex>
                <Flex>
                    <FormLabel>LAND</FormLabel>
                    <Input disabled placeholder="LAND" {...register("land")} />
                </Flex>
            </Wrap>
        </Box>
    )
}
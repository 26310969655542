import { Button, Link, useColorMode, useToast } from '@chakra-ui/react'
import { Table, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/table'
import { useContext, useEffect, useState } from 'react'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import { ShortcutContext } from '../../../context/ShortcutContext';
import { KullFilter, useKullEnums, useSearchKull } from '../../../service/service';
import { getValueForEnumCode } from '../../../types/FormEnum';
import { Kull } from '../../../types/Kull';
import { useKeyPress } from '../../../utils/hooks/KeyboardShortcutHooks';
import { CenterSpinner } from '../../Globals/CenterSpinner';
import { KullFilterSelect } from './KullFilterSelect';


export default function KullTable() {

    const { colorMode, toggleColorMode } = useColorMode();
    const [filter, setFilter] = useState<KullFilter>({ min_kull_nr: undefined, max_kull_nr: undefined, kennel: undefined, kullb: undefined })
    const eRes = useKullEnums()
    const navigate = useNavigate()
    const { state, dispatch } = useContext(ShortcutContext)

    const toast = useToast()

    const { data, isLoading, isError, error } = useSearchKull(filter, "modified_at")

    useEffect(() => {
        if (isError) {
            toast({
                title: "Kunne ikke laste kull",
                description: "Feil ved lasting av kull: " + error,
                status: "error"
            })
        }

    }, [isLoading])

    const multi = () => navigate("/")
    useKeyPress(["F10"], [], multi)
    useEffect(() => {
        dispatch({ contextId: "KullTable", id: "9_multi", shortcut: { keys: "F10", description: "MULTI", fn: multi } })
    }, [])

    const opprettKull = () => navigate("/kull")
    useKeyPress(["F2"], [], opprettKull)
    useEffect(() => {
        dispatch({ contextId: "KullTable", id: "2_opprett_kull", shortcut: { keys: "F2", description: "OPPRETT NY", fn: opprettKull } })
    }, [])

    const tableRows = (data && data.kull.length > 0) ? data.kull.map((k: Kull, i: number) =>
        <Tr key={i}>
            <Td>{k.kullnr}</Td>
            <Td>{k.kennel}</Td>
            <Td>{k.kullb}</Td>
            <Td>{(eRes.data) ? getValueForEnumCode(eRes.data.fd_land, k.fd_land_id) : k.fd_land_id}</Td>
            <Td>{(!k.fodt) ? "Dato ikke satt" : new Date(k.fodt).toLocaleDateString("en-GB")}</Td>
            <Td>
                <Link as={RouterLink} to={"/kull/" + encodeURIComponent(k.kullnr)}><Button>Se/Oppdater</Button></Link>
            </Td>
        </Tr>
    ) : []

    return (
        <TableContainer>
            <KullFilterSelect filter={filter} setFilter={(f) => { let k_filter: KullFilter = { ...f }; setFilter({ ...f, max_kull_nr: k_filter.min_kull_nr }) }} height={"10%"} />
            <Table>
                <Thead>
                    <Tr>
                        <Th>Kullnr</Th>
                        <Th>Kennel</Th>
                        <Th>Kullbokstav</Th>
                        <Th>Land</Th>
                        <Th>Født</Th>
                        <Th>Aksjoner</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {tableRows}
                </Tbody>
            </Table>
            {isLoading && <CenterSpinner />}
        </TableContainer>
    )
}

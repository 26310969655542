import { Key, useCallback, useEffect, useLayoutEffect, useRef } from 'react';

type ModifierKey = "shift" | "ctrl" | "alt" | "meta"

export const useKeyPress = (keys: Key[], modifierKeys: ModifierKey[], callback: (event: KeyboardEvent) => any, node: Node | undefined = undefined, isInactive: boolean | undefined = undefined) => {
    // implement the callback ref pattern
    const callbackRef = useRef(callback);
    useLayoutEffect(() => {
        callbackRef.current = callback;
    });

    // handle what happens on key press
    const handleKeyPress: EventListenerOrEventListenerObject = useCallback(
        (event: Event) => {
            if (isInactive) {
                return
            }

            // check if one of the key is part of the ones we want
            let k_event = event as KeyboardEvent

            if (modifierKeys.includes("shift") && !k_event.shiftKey) return
            if (modifierKeys.includes("ctrl") && !k_event.ctrlKey) return
            if (modifierKeys.includes("alt") && !k_event.altKey) return
            if (modifierKeys.includes("meta") && !k_event.metaKey) return

            if (keys.some((key) => k_event.key === key)) {
                k_event.preventDefault()
                callbackRef.current(k_event);
            }
        },
        [keys]
    );

    useEffect(() => {
        // target is either the provided node or the document
        const targetNode = node ?? document;
        // attach the event listener
        targetNode &&
            targetNode.addEventListener("keydown", handleKeyPress);

        // remove the event listener
        return () =>
            targetNode &&
            targetNode.removeEventListener("keydown", handleKeyPress);
    }, [handleKeyPress, node]);
};
import { Box, BoxProps, Button, Flex, FormLabel, Input, Wrap } from '@chakra-ui/react';
import React from 'react';
import { useForm } from 'react-hook-form';
import { KullFilter } from '../../../service/service';


type FilterProps = { filter?: KullFilter; setFilter: React.Dispatch<React.SetStateAction<KullFilter>>; } & BoxProps
export function KullFilterSelect(props: FilterProps) {

    const { register, handleSubmit } = useForm<KullFilter>({ defaultValues: props.filter });

    const onSubmit = (data: KullFilter) => { console.log("Setting!"); props.setFilter(data) };

    return (
        <Box {...props}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Wrap>
                    <Flex>
                        <FormLabel width={20}>Kullnr</FormLabel>
                        <Input  {...register("min_kull_nr")} width={'24'} type="number" />
                    </Flex>
                    <Flex>
                        <FormLabel width={20}>Kennel</FormLabel>
                        <Input  {...register("kennel")} />
                    </Flex>
                    <Flex>
                        <FormLabel width={20}>Kullbokstav</FormLabel>
                        <Input {...register("kullb")} width={'10'} />
                    </Flex>
                    <Button type="submit"> Søk </Button>
                </Wrap>
            </form>
        </Box>
    );
}

import { Box, BoxProps, Button, Flex, FormLabel, Input, Wrap } from '@chakra-ui/react';
import React from 'react';
import { useForm } from 'react-hook-form';
import { HundFilter } from '../../../service/service';


type FilterProps = { filter?: HundFilter; setFilter: React.Dispatch<React.SetStateAction<HundFilter>>; } & BoxProps
export function DogFilterSelect(props: FilterProps) {

    const { register, handleSubmit } = useForm<HundFilter>({ defaultValues: props.filter });

    const onSubmit = (data: HundFilter) => props.setFilter(data);

    return (
        <Box {...props}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Wrap>
                    <Flex>
                        <FormLabel width={20}>REG.NR</FormLabel>
                        <Input placeholder="REG_NR" {...register("reg_nr")} />
                    </Flex>
                    <Flex>
                        <FormLabel width={20}>NAVN</FormLabel>
                        <Input placeholder="navn" {...register("navn")} />
                    </Flex>
                    <Button type="submit"> Søk </Button>
                </Wrap>
            </form>
        </Box>
    );
}

// theme.ts (tsx file with usage of StyleFunctions, see 4.)
import { extendTheme, ThemeConfig } from '@chakra-ui/react'
import { inputTheme } from './inputTheme'


const config: ThemeConfig = {
  initialColorMode: "dark",
  useSystemColorMode: false,
}

extendTheme(config)

const extendedTheme = extendTheme({
  ...config,
  components: {
    FormLabel: {
      baseStyle: {
        alignSelf: "end",
        whiteSpace: "nowrap"
      },
    },
    Input: inputTheme
  },
})

export default extendedTheme
import { Box, Button, Link, Skeleton, Spinner, useColorMode, useToast } from '@chakra-ui/react'
import { Table, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/table'
import { useContext, useEffect, useState } from 'react'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import { ShortcutContext } from '../../../context/ShortcutContext';
import { HundFilter, useHunderEnums, useSearchHund } from '../../../service/service';
import { CONTENT_HEIGHT } from '../../../theming/constants';
import { getValueForEnumCode } from '../../../types/FormEnum';
import { Hund } from '../../../types/Hund';
import { useKeyPress } from '../../../utils/hooks/KeyboardShortcutHooks';
import { CenterSpinner } from '../../Globals/CenterSpinner';
import { DogFilterSelect } from './DogFilterSelect';


export default function DogTable() {

    const [filter, setFilter] = useState<HundFilter>({ navn: undefined, reg_nr: undefined })
    const eRes = useHunderEnums()
    const navigate = useNavigate()
    const { state, dispatch } = useContext(ShortcutContext)

    const toast = useToast()

    const { data, isLoading, isError, error } = useSearchHund(filter)

    useEffect(() => {
        if (isError) {
            toast({
                title: "Kunne ikke laste hunder",
                description: "Feil ved lasting av hunder: " + error,
                status: "error"
            })
        }

    }, [isLoading])

    const multi = () => navigate("/")
    useKeyPress(["F10"], [], multi)
    useEffect(() => {
        dispatch({ contextId: "HundTable", id: "9_multi", shortcut: { keys: "F10", description: "MULTI", fn: multi } })
    }, [])

    const hundForm = () => navigate("/hund")
    useKeyPress(["F2"], [], hundForm)
    useEffect(() => {
        dispatch({ contextId: "HundTable", id: "0_hund", shortcut: { keys: "F2", description: "OPPRETT NY", fn: hundForm } })
    }, [])

    const tableRows = (data && data.length > 0) ? data.map((h: Hund, i: number) =>
        <Tr key={i}>
            <Td>{h.reg_nr}</Td>
            <Td>{(!h.fodt) ? "Dato ikke satt" : new Date(h.fodt).toLocaleDateString("en-GB")}</Td>
            <Td>{(eRes.data) ? getValueForEnumCode(eRes.data.fd_land, h.fd_land_id) : h.fd_land_id}</Td>
            <Td>{h.kullnr}</Td>
            <Td>{h.navn}</Td>
            <Td>
                <Link as={RouterLink} to={"/hund/" + encodeURIComponent(h.reg_nr)}><Button>Se/Oppdater</Button></Link>
            </Td>
        </Tr>
    ) : []

    return (
        <TableContainer>
            <DogFilterSelect filter={filter} setFilter={setFilter} />
            <Table>
                <Thead>
                    <Tr>
                        <Th>Reg.Nr</Th>
                        <Th>Født</Th>
                        <Th>FD-L</Th>
                        <Th>KULLN</Th>
                        <Th>NAVN</Th>
                        <Th>Aksjoner</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {tableRows}
                </Tbody>
            </Table>
            {isLoading && <CenterSpinner />}

        </TableContainer>
    )
}

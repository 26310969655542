import { Box, BoxProps, Flex, FormLabel, Input, Wrap, Text, useToast, Button, HStack } from '@chakra-ui/react'
import { useContext, useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { ShortcutContext } from '../../../context/ShortcutContext'
import { useHunderEnums, HundFilter, useSearchHund } from '../../../service/service'
import { getValueForEnumCode } from '../../../types/FormEnum'
import { Hund } from '../../../types/Hund'
import { useKeyPress } from '../../../utils/hooks/KeyboardShortcutHooks'
import { CenterSpinner } from '../../Globals/CenterSpinner'
import SearchableInput from '../SearchableInput'

type ParamType = BoxProps & { parentname: "MOR" | "FAR" }

type ForelderInfo = { farens_hd?: string, morens_hd?: string, farens_ad?: string, morens_ad?: string, farens_mer?: string, morens_mer?: string }

export const DogParentForm = (props: ParamType) => {

    const { register, setValue, getValues } = useFormContext<Hund & ForelderInfo>();
    const { isLoading, isError, data: hunderEnums } = useHunderEnums()
    const [filter, setFilter] = useState<HundFilter>({})
    const { isLoading: hundLoading, isFetching, data, refetch } = useSearchHund(filter, undefined, 1, { enabled: false, refetchOnWindowFocus: false })
    const { state, dispatch } = useContext(ShortcutContext)
    const [href, setHref] = useState<string | null>(null)

    const toast = useToast()

    const getRegNr = () => getValues(((props.parentname === "FAR") ? "farens_reg_nr" : "morens_reg_nr"))
    const getName = () => getValues(((props.parentname === "FAR") ? "far" : "mor"))
    const regNr = getRegNr()
    const name = getName()

    const findByRegNr = () => {
        setFilter({ reg_nr: getRegNr() || undefined, navn: undefined })
    }

    const findByName = () => {
        setFilter({ reg_nr: undefined, navn: getName() || undefined })
    }

    let keys = ((props.parentname === "FAR") ? ["F", "f"] : ["M", "m"])
    useKeyPress(keys, ["alt"], findByRegNr)

    useEffect(() => {
        dispatch({
            contextId: "DogForm",
            id: "7" + props.parentname,
            shortcut: {
                keys: "ALT + " + keys[0],
                description: "HENT " + props.parentname,
                fn: findByRegNr
            }
        })
    }, [])

    useEffect(() => {
        if (regNr) findByRegNr()
        else if (name) findByName()
    }, [regNr, name])

    useEffect(() => {
        if (filter.reg_nr || filter.navn) {
            refetch()
        }
    }, [filter])

    useEffect(() => {
        if (!hundLoading && data) {
            const hund = data[0]
            if (hund) {
                setValue(((props.parentname === "FAR") ? "farens_reg_nr" : "morens_reg_nr"), hund.reg_nr)
                setValue(((props.parentname === "FAR") ? "far" : "mor"), hund.navn)
                setValue(((props.parentname === "FAR") ? "farens_hd" : "morens_hd"), getValueForEnumCode(hunderEnums?.hd, hund.hd_id))
                setValue(((props.parentname === "FAR") ? "farens_ad" : "morens_ad"), getValueForEnumCode(hunderEnums?.ad, hund.ad_id))
                setValue(((props.parentname === "FAR") ? "farens_mer" : "morens_mer"), hund.mer)
                setHref("/hund/" + encodeURIComponent(hund.reg_nr))
            } else {
                toast({
                    title: "Hund ikke funnet",
                    description: "Kunne ikke finne " + props.parentname.toLowerCase() + " fra gitte verdier",
                    status: 'warning'
                })
            }

        }
    }, [isFetching, hundLoading])

    return (
        <Box {...props}>
            {isFetching && <CenterSpinner />}
            <HStack marginLeft={20} alignItems="center" flexDir="row">
                <Text align={'left'}>{props.parentname}</Text>
                {href && <Button as="a" href={href} variant="outline">Gå til</Button>}
            </HStack>
            <Box border={'2px solid black'}>
                <Wrap>
                    <Flex>
                        <FormLabel width={20} alignSelf={'end'}>REG.NR</FormLabel>
                        <SearchableInput placeholder="REG_NR" useFormRegister={register((props.parentname === "FAR") ? "farens_reg_nr" : "morens_reg_nr")} onClick={findByRegNr} />
                    </Flex>
                    <Flex>
                        <FormLabel>MER</FormLabel>
                        <Input disabled {...register((props.parentname === "FAR") ? "farens_mer" : "morens_mer")} />
                    </Flex>
                </Wrap>
                <Wrap>
                    <Flex>
                        <FormLabel width={20}>NAVN</FormLabel>
                        <SearchableInput placeholder="NAVN" useFormRegister={register((props.parentname === "FAR") ? "far" : "mor")} onClick={findByName} />
                    </Flex>
                    <Flex>
                        <FormLabel>HD</FormLabel>
                        <Input disabled {...register((props.parentname === "FAR") ? "farens_hd" : "morens_hd")} />
                    </Flex>
                    <Flex>
                        <FormLabel>AD</FormLabel>
                        <Input disabled {...register((props.parentname === "FAR") ? "farens_ad" : "morens_ad")} />
                    </Flex>
                </Wrap>
            </Box>
        </Box>
    )
}
import { ChakraStylesConfig, Select } from 'chakra-react-select'
import { Controller, FieldValues, Path } from 'react-hook-form'
import { FormEnumArray, selectOptionsFromEnumArray } from '../../types/FormEnum'
import { Hund } from '../../types/Hund'
import { Kull } from '../../types/Kull'
import { Oppdretter } from '../../types/Oppdretter'
import { Poststed } from '../../types/Poststed'

interface EnumSelectProps<T extends FieldValues> {
    enumArray?: FormEnumArray,
    name: Path<T>
}

export const chakraStyle = (enumArray: FormEnumArray = []): ChakraStylesConfig => {

    if (enumArray.length === 0) {
        return {}
    }

    const lengthMap = enumArray.map((v) => {
        return {
            len: (v.id.toString().length + ((v.value != null) ? v.value.toString().length : 0) + 2),
            str: (v.id + ': ' + v.value)
        }
    })
    const maxValue = lengthMap.reduce((max, val) => {
        if (val.len > max.len) return val
        else return max
    })

    return {
        inputContainer: (provided, state) => ({
            ...provided,
            minWidth: maxValue.len * 0.5 + 'rem',
        })
    }
}

export const EnumSelect = <T extends FieldValues>({ enumArray, name }: EnumSelectProps<T>) => {

    const options = selectOptionsFromEnumArray(enumArray)

    return <Controller name={name} render={
        ({ field: { onChange, value, name, ref } }) => (
            <Select
                options={options}
                useBasicStyles
                chakraStyles={chakraStyle(enumArray)}
                onChange={(e: any) => {
                    onChange(e.value)
                }}
                ref={ref}
                name={name}
                value={
                    options && value
                        ? options.find((option) => option.value === value)
                        : undefined
                }
            />
        )
    } />
}

export const HundEnumSelect = (props: EnumSelectProps<Hund>) => EnumSelect<Hund>(props)
export const KullEnumSelect = (props: EnumSelectProps<Kull>) => EnumSelect<Kull>(props)
export const PoststedEnumSelect = (props: EnumSelectProps<Poststed>) => EnumSelect<Poststed>(props)
export const OppdretterEnumSelect = (props: EnumSelectProps<Oppdretter>) => EnumSelect<Oppdretter>(props)

export default EnumSelect
import { SearchIcon } from '@chakra-ui/icons'
import { IconButton, Input, InputGroup, InputRightElement, InputProps } from '@chakra-ui/react'
import { UseFormRegisterReturn } from 'react-hook-form'

type SearchableInputProps = { useFormRegister: UseFormRegisterReturn, onClick: () => void } & InputProps
export default function SearchableInput({ useFormRegister: register, onClick, ...props }: SearchableInputProps) {
    return (
        <InputGroup>
            <Input {...props} {...register} />
            <InputRightElement>
                <IconButton icon={<SearchIcon />} aria-label={"Search and autofill"} onClick={onClick} size="sm" />
            </InputRightElement>
        </InputGroup>
    )
}

import { Box, Button, Flex } from '@chakra-ui/react'
import { useContext } from 'react'
import { ShortcutContext } from '../../context/ShortcutContext'


type ShortcutBarProps = {
    height: string
}

export const ShortcutBar = (props: ShortcutBarProps) => {

    const { state } = useContext(ShortcutContext)

    const buttons = () => {
        return Object.entries(state.shortcuts).sort((a, b) => { return a[0].localeCompare(b[0]) }).map(([k, s]) => {
            return <Button key={k} onClick={s.fn} size="md">{s.keys + ": " + s.description}</Button>
        })
    }

    return (
        <Box as="footer" margin={0} padding={0} height={props.height} overflowY="auto">
            {state.context && <Flex wrap={'wrap'} height='100%' width="100%" direction="row" backgroundColor={'Menu'}>
                {buttons()}
            </Flex>}
        </Box>
    )
}

export type FormEnum = { "id": number, "value": string }
export type FormEnumArray = FormEnum[]

export function getValueForEnumCode(enumObj?: FormEnumArray, id?: number): string | undefined {
    if (!id || !enumObj) return undefined
    return enumObj.find(v => v.id === id)?.value
}

export function selectOptionsFromEnumArray(array?: FormEnumArray, includeId: boolean = false) {
    if (!array) return []
    return array.map(v => ({ value: v.id, label: (includeId ? v.id + ": " : "")  + ((v.value) ? v.value : "") }))
}

export type HunderEnums = {
    "ad": FormEnumArray,
    "bruks": FormEnumArray,
    "bruks2": FormEnumArray,
    "bruks3": FormEnumArray,
    "bruks4": FormEnumArray,
    "fd_land": FormEnumArray,
    "hd": FormEnumArray,
    "hem": FormEnumArray,
    "k": FormEnumArray,
    "kjonn": FormEnumArray,
    "kkl": FormEnumArray,
    "kval": FormEnumArray,
    "kval2": FormEnumArray,
    "prem": FormEnumArray,
    "test": FormEnumArray,
    "utd": FormEnumArray,
    "utd2": FormEnumArray,
    "utd3": FormEnumArray,
    "utmer": FormEnumArray,
    "vin": FormEnumArray,
    "zb": FormEnumArray
}

export type KullEnums = {
    "fd_land": FormEnumArray,
    "kjonn": FormEnumArray,
    "meritter": FormEnumArray,
    "hd": FormEnumArray
}

export type PoststedEnums = {
    "land": FormEnumArray,
    "fylke": FormEnumArray
}

export type OppdretterEnums = {
    "prefiks": FormEnumArray,
    "fd_land": FormEnumArray,
    "oprefiks": FormEnumArray
}
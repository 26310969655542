import './App.css';

import { Box, ChakraProvider, useColorMode } from '@chakra-ui/react'
import extendedTheme from './theming/FormLabelTheme';
import DogForm from './components/Form/Dog/DogForm';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ShortcutContextProvider } from './context/ShortcutContext';
import { ShortcutBar } from './components/Globals/ShortcutBar';
import { LandingPage } from './components/LandingPage/LandingPage';
import KullForm from './components/Form/Kull/KullForm';
import DogTable from './components/Table/Dog/DogTable';
import KullTable from './components/Table/Kull/KullTable';
import OppdretterForm from './components/Form/Oppdretter/OppdretterForm';
import { FC } from 'react';
import { HasChildren } from './types/HasChildren';
import { ReactQueryDevtools } from 'react-query/devtools'
import PoststedForm from './components/Form/Poststed/PoststedForm';
import OppdretterTable from './components/Table/Oppdretter/OppdretterTable';
import PoststedTable from './components/Table/Poststed/PostStedTable';

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_USERPOOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_USERPOOL_WEB_CLIENT_ID,
    oauth: {
      domain: process.env.REACT_APP_OAUTH_DOMAIN,
      scope: [],
      redirectSignIn: process.env.REACT_APP_OAUTH_SIGN_IN,
      redirectSignOut: process.env.REACT_APP_OAUTH_SIGN_OUT,
      responseType: "code"
    }
  }
})

const queryClient = new QueryClient({ defaultOptions: { queries: { staleTime: 360000 } } })

const AppWrapper: FC<HasChildren> = ({ children }) => {

  const { setColorMode } = useColorMode()

  setColorMode("dark")

  return (
    <div>{children}</div>
  )
}


function App() {

  return (
    <QueryClientProvider client={queryClient}>
      <ChakraProvider theme={extendedTheme}>
        <AppWrapper>
          <ShortcutContextProvider>
            <BrowserRouter>
              <Box height={"100%"} overflow={'hidden'}>
                <Box height={"90vh"} overflow={'scroll'}>
                  <Routes>
                    <Route index element={<LandingPage />} />
                    <Route path="hunder" element={<DogTable />} />
                    <Route path="hund/:reg_nr?" element={<DogForm />} />
                    <Route path="hund/dupliser/:reg_nr" element={<DogForm duplicate />} />
                    <Route path="kull/:kull_nr?" element={<KullForm />} />
                    <Route path="kull_tabell" element={<KullTable />} />
                    <Route path="oppdretter/:id?" element={<OppdretterForm />} />
                    <Route path="oppdretter_tabell" element={< OppdretterTable />} />
                    <Route path="poststed/:post_nr?" element={<PoststedForm />} />
                    <Route path="poststeder" element={<PoststedTable />} />
                  </Routes>
                </Box>
                <ShortcutBar height={"10vh"} />
              </Box>
            </BrowserRouter>
          </ShortcutContextProvider>
        </AppWrapper>
      </ChakraProvider>
      <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
    </QueryClientProvider>
  );
}

export default withAuthenticator(App);

import { useToast } from "@chakra-ui/react";
import { Auth } from "aws-amplify";
import { QueryKey, useMutation, useQuery, useQueryClient, UseQueryOptions } from "react-query";
import { HunderEnums, KullEnums, OppdretterEnums, PoststedEnums } from "../types/FormEnum";
import { Hund } from "../types/Hund";
import { Kull, KullQueryModel } from "../types/Kull";
import { Oppdretter, OppdretterQueryModel } from "../types/Oppdretter";
import { Poststed, PoststedQueryModel } from "../types/Poststed";

export type HundFilter = { "reg_nr"?: string, "navn"?: string }
export type Options<T> = Omit<UseQueryOptions<T, unknown, T, QueryKey>, "queryKey" | "queryFn">

export const pruneAndStringify = (obj: { [key: string]: any | undefined }) => {
    let res: { [key: string]: string } = {}
    Object.keys(obj).forEach(key => {
        if (obj[key] && obj[key].toString()) {
            res[key] = obj[key].toString()
        }
    }
    )
    console.log(res)
    return res
}

export const prune = (obj: { [key: string]: any | undefined }) => {
    let res: { [key: string]: string } = {}
    Object.keys(obj).forEach(key => {
        if (obj[key]) {
            res[key] = obj[key]
        }
    }
    )
    console.log(res)
    return res
}

export const useSearchHund = (filter: HundFilter, reg_nr_offset?: string, limit?: number, options?: Options<Hund[]>) => useQuery<Hund[]>(['searchHund', { filter, reg_nr_offset: reg_nr_offset, limit }], () =>
    Auth.currentSession()
        .then(res => {
            return res.getIdToken()
        })
        .then(token => {
            let { reg_nr, navn } = filter
            return fetch(process.env.REACT_APP_API_ENDPOINT + "hund/list?" + new URLSearchParams(pruneAndStringify({
                "reg_nr": reg_nr,
                "navn": navn,
                "reg_nr_offset": reg_nr_offset,
                "limit": limit?.toString()
            })), {
                headers: {
                    "Content-type": "application/json",
                    "Authorization": `Bearer ${token.getJwtToken()}`
                }
            })
        })
        .then(res => {
            return res.json()
        })
        .catch(err => { throw err })
    , options);


export const useGetHund = (reg_nr?: string, options?: Options<Hund>) => useQuery<Hund>(['getHund', { reg_nr: reg_nr }], () =>
    Auth.currentSession()
        .then(res => {
            return res.getIdToken()
        })
        .then(token => {
            if (!reg_nr) throw new Error("reg_nr not set");
            return fetch(process.env.REACT_APP_API_ENDPOINT + "hund/" + encodeURIComponent(reg_nr), {
                headers: {
                    "Content-type": "application/json",
                    "Authorization": `Bearer ${token.getJwtToken()}`
                }
            })
        })
        .then(res => {
            return res.json()
        })
        .catch(err => { throw err })
    , options);

export const usePutHund = () => {
    const queryClient = useQueryClient()
    const toast = useToast()

    return useMutation((h) => {
        return Auth.currentSession()
            .then(res => {
                return res.getIdToken()
            })
            .then(token => {
                return fetch(process.env.REACT_APP_API_ENDPOINT + "hund/", {
                    method: "PUT",
                    body: JSON.stringify(pruneAndStringify(h)),
                    headers: {
                        "Content-type": "application/json",
                        "Authorization": `Bearer ${token.getJwtToken()}`
                    }
                })
            })
            .then(async res => {
                return { isOk: res.ok, body: await res.json() }
            })
            .then(v => {
                const { isOk, body } = v
                console.log(v)
                if (!isOk) {
                    throw Error("Feilet grunnet: " + JSON.stringify(body.errors))
                }
            })
    }
        , {
            onMutate: async (hund: Hund) => {
                return { hund }
            },
            onError: (err, variables, context) => {
                toast({ title: "Oppdatering feilet:", description: (err as Error).message, status: "error" })
                console.log(err)
            },
            onSuccess: (data, variables, context) => {
                toast({ title: "Hund Oppdatert!", status: "success" })
                queryClient.invalidateQueries(['getHund', { reg_nr: context?.hund.reg_nr }])
                queryClient.invalidateQueries('searchHund')
            }
        })
}

export const usePostHund = () => {
    const queryClient = useQueryClient()
    const toast = useToast()

    return useMutation((h) => {
        return Auth.currentSession()
            .then(res => {
                return res.getIdToken()
            })
            .then(token => {
                return fetch(process.env.REACT_APP_API_ENDPOINT + "hund/", {
                    method: "POST",
                    body: JSON.stringify(pruneAndStringify(h)),
                    headers: {
                        "Content-type": "application/json",
                        "Authorization": `Bearer ${token.getJwtToken()}`
                    }
                })
            })
            .then(async res => {
                return { isOk: res.ok, body: await res.json() }
            })
            .then(v => {
                const { isOk, body } = v
                console.log(v)
                if (!isOk) {
                    throw Error("Feilet grunnet: " + JSON.stringify(body))
                }
            })
    }
        , {
            onMutate: async (hund: Hund) => {
                return { hund }
            },
            onError: (err, variables, context) => {
                toast({ title: "Oppretting feilet:", description: (err as Error).message, status: "error" })
                console.log(err)
            },
            onSuccess: (data, variables, context) => {
                toast({ title: "Hund Opprettet!", status: "success" })
                queryClient.invalidateQueries(['getHund', { reg_nr: context?.hund.reg_nr }])
                queryClient.invalidateQueries('searchHund')
            }
        })
}

export const useDeleteHund = () => {
    const queryClient = useQueryClient()
    const toast = useToast()

    return useMutation((regNr) => {
        return Auth.currentSession()
            .then(res => {
                return res.getIdToken()
            })
            .then(token => {
                return fetch(process.env.REACT_APP_API_ENDPOINT + "hund/" + encodeURIComponent(regNr), {
                    method: "DELETE",
                    headers: {
                        "Content-type": "application/json",
                        "Authorization": `Bearer ${token.getJwtToken()}`
                    }
                })
            })
            .then(async res => {
                return { isOk: res.ok }
            })
            .then(v => {
                const { isOk } = v
                if (!isOk) {
                    throw Error("Sletting feilet")
                }
            })
    }
        , {
            onMutate: async (regNr: string) => {
                return { regNr }
            },
            onError: (err, variables, context) => {
                toast({ title: "Sletting feilet:", description: (err as Error).message, status: "error" })
                console.log(err)
            },
            onSuccess: (data, variables, context) => {
                toast({ title: "Hund Slettet!", status: "success" })
                queryClient.invalidateQueries(['getHund', { reg_nr: context?.regNr }])
                queryClient.invalidateQueries('searchHund')
            }
        })
}

export const useGetHundStamtavleHtml = () => {
    const toast = useToast()
    return {
        download: (reg_nr?: string) => Auth.currentSession()
            .then(res => {
                return res.getIdToken()
            })
            .then(token => {
                if (!reg_nr) {
                    toast({ title: "Kunne ikke hente stamtavle", description: "Hund har ikke reg_nr", status: "error" })
                    throw new Error("reg_nr not set")
                }
                return fetch(process.env.REACT_APP_API_ENDPOINT + "hund/stamtavle/" + encodeURIComponent(reg_nr) + "/html", {
                    headers: {
                        "Content-type": "application/json",
                        "Authorization": `Bearer ${token.getJwtToken()}`
                    }
                })
            })
            .then(res => {
                if (res.ok) {
                    return res
                } else {
                    toast({ title: "Kunne ikke hente stamtavle", description: "Feil ved kall til database", status: "error" })
                    throw new Error("Response from api was " + res.status)
                }
            })
            .then(res => {
                res.blob()
                    .then(blob => {
                        const url = window.URL.createObjectURL(blob)
                        const a = document.createElement("a")
                        a.style.display = "none"
                        a.href = url
                        a.download = encodeURIComponent(reg_nr!!.replace("/", "_")) + "_stamtavle.html"
                        document.body.appendChild(a)
                        a.click()
                        window.URL.revokeObjectURL(url)
                        toast({ title: "Stamtavle er lastet ned", description: "Klikk feltet nede til venstre for å åpne, og printe", status: "success" })
                    })
            })
            .catch(err => { throw err })
    }
}


export const useHunderEnums = (options: Options<HunderEnums> | undefined = undefined) => useQuery<HunderEnums>(['https://api.dev.tveitmo.com/hund/property/all'], () =>
    Auth.currentSession()
        .then(res => {
            return res.getIdToken()
        })
        .then(token => {
            return fetch(process.env.REACT_APP_API_ENDPOINT + "hund/property/all", {
                headers: {
                    "Content-type": "application/json",
                    "Authorization": `Bearer ${token.getJwtToken()}`
                }
            })
        })
        .then(res => {
            return res.json()
        })
        .then(json => {
            return json
        })
        .catch(error => { throw error })
    , options);


export type KullFilter = {
    min_kull_nr?: string,
    max_kull_nr?: string,
    kennel?: string,
    kullb?: string
}

type NextValue = {
    next: number
}

export function getNewKullnrSync(isGerman: boolean): Promise<NextValue> {
    return Auth.currentSession()
        .then(res => {
            return res.getIdToken();
        })
        .then(token => {
            return fetch(process.env.REACT_APP_API_ENDPOINT + "kull/next?german=" + isGerman, {
                headers: {
                    "Content-type": "application/json",
                    "Authorization": `Bearer ${token.getJwtToken()}`
                }
            });
        })
        .then(res => {
            return res.json();
        })
        .catch(err => { throw err; });
}

export const useGetKull = (kull_nr?: number, options?: Options<Kull>) => useQuery<Kull>(['getKull', { kull_nr: kull_nr }], () =>
    Auth.currentSession()
        .then(res => {
            return res.getIdToken()
        })
        .then(token => {
            if (!kull_nr) throw new Error("kull_nr not set");
            return fetch(process.env.REACT_APP_API_ENDPOINT + "kull/" + encodeURIComponent(kull_nr), {
                headers: {
                    "Content-type": "application/json",
                    "Authorization": `Bearer ${token.getJwtToken()}`
                }
            })
        })
        .then(res => {
            return res.json()
        })
        .catch(err => { throw err })
    , options);

export const usePutKull = () => {
    const queryClient = useQueryClient()
    const toast = useToast()

    return useMutation((k) => {
        console.log(k)
        return Auth.currentSession()
            .then(res => {
                return res.getIdToken()
            })
            .then(token => {
                return fetch(process.env.REACT_APP_API_ENDPOINT + "kull/", {
                    method: "PUT",
                    body: JSON.stringify(prune(k)),
                    headers: {
                        "Content-type": "application/json",
                        "Authorization": `Bearer ${token.getJwtToken()}`
                    }
                })
            })
            .then(async res => {
                return { isOk: res.ok, body: await res.json() }
            })
            .then(v => {
                const { isOk, body } = v
                console.log(v)
                if (!isOk) {
                    throw Error("Feilet grunnet: " + JSON.stringify(body.errors))
                }
            })
    }
        , {
            onMutate: async (kull: Kull) => {
                return { kull }
            },
            onError: (err, variables, context) => {
                toast({ title: "Oppdatering feilet:", description: (err as Error).message, status: "error" })
                console.log(err)
            },
            onSuccess: (data, variables, context) => {
                toast({ title: "Kull Oppdatert!", status: "success" })
                queryClient.invalidateQueries(['getKull', { kull_nr: context?.kull.kullnr }])
                queryClient.invalidateQueries('searchKull')
                queryClient.invalidateQueries('getNewKullNr')
            }
        })
}

export const usePostKull = () => {
    const queryClient = useQueryClient()
    const toast = useToast()

    return useMutation((h) => {
        return Auth.currentSession()
            .then(res => {
                return res.getIdToken()
            })
            .then(token => {
                return fetch(process.env.REACT_APP_API_ENDPOINT + "kull/", {
                    method: "POST",
                    body: JSON.stringify(prune(h)),
                    headers: {
                        "Content-type": "application/json",
                        "Authorization": `Bearer ${token.getJwtToken()}`
                    }
                })
            })
            .then(async res => {
                return { isOk: res.ok, body: await res.json() }
            })
            .then(v => {
                const { isOk, body } = v
                console.log(v)
                if (!isOk) {
                    throw Error("Feilet grunnet: " + JSON.stringify(body))
                }
            })
    }
        , {
            onMutate: async (kull: Kull) => {
                return { kull }
            },
            onError: (err, variables, context) => {
                toast({ title: "Oppretting feilet:", description: (err as Error).message, status: "error" })
                console.log(err)
            },
            onSuccess: (data, variables, context) => {
                toast({ title: "Kull Opprettet!", status: "success" })
                queryClient.invalidateQueries(['getKull', { reg_nr: context?.kull.kullnr }])
                queryClient.invalidateQueries('searchKull')
                queryClient.invalidateQueries('getNewKullNr')
            }
        })
}

export const useDeleteKull = () => {
    const queryClient = useQueryClient()
    const toast = useToast()

    return useMutation((regNr) => {

        return Auth.currentSession()
            .then(res => {
                return res.getIdToken()
            })
            .then(token => {
                return fetch(process.env.REACT_APP_API_ENDPOINT + "kull/" + encodeURIComponent(regNr), {
                    method: "DELETE",
                    headers: {
                        "Content-type": "application/json",
                        "Authorization": `Bearer ${token.getJwtToken()}`
                    }
                })
            })
            .then(async res => {
                return { isOk: res.ok }
            })
            .then(v => {
                const { isOk } = v
                if (!isOk) {
                    if (!regNr) {
                        throw Error("Kullnr er ikke satt. Hvis feltet er tomt, skriv inn 0 i det")
                    }
                    throw Error("Sletting feilet")
                }
            })
    }
        , {
            onMutate: async (regNr: string) => {
                return { regNr }
            },
            onError: (err, variables, context) => {
                toast({ title: "Sletting feilet:", description: (err as Error).message, status: "error" })
                console.log(err)
            },
            onSuccess: (data, variables, context) => {
                toast({ title: "Kull Slettet!", status: "success" })
                queryClient.invalidateQueries(['getKull', { reg_nr: context?.regNr }])
                queryClient.invalidateQueries('searchKull')
            }
        })
}

export const useSearchKull = (filter: KullFilter, sortKey: "kullnr" | "modified_at", page?: number, limit?: number, options?: Options<KullQueryModel>) => useQuery<KullQueryModel>(['searchKull', { filter, sortKey: sortKey, page: page, limit }], () =>
    Auth.currentSession()
        .then(res => {
            return res.getIdToken()
        })
        .then(token => {
            return fetch(process.env.REACT_APP_API_ENDPOINT + "kull/list?" + new URLSearchParams(pruneAndStringify({
                ...filter,
                "sortKey": sortKey,
                "page": page,
                "limit": limit?.toString()
            })), {
                headers: {
                    "Content-type": "application/json",
                    "Authorization": `Bearer ${token.getJwtToken()}`
                }
            })
        })
        .then(res => {
            return res.json()
        })
        .catch(err => { throw err })
    , options);

export type OppdretterFilter = {
    kennel?: string,
    kunkennel?: string,
    nkennel?: string,
    prefiks_id?: number,
    oprefiks_id?: number,
    fd_land_id?: number,
    oppdr?: string,
    addresse?: string,
    postnr?: string,
    tlf?: string,
    tlfa?: string,
    tlfm?: string,
}

export const useGetOppdretter = (id?: number, options?: Options<Oppdretter>) => useQuery<Oppdretter>(['getOppdretter', { id }], () =>
    Auth.currentSession()
        .then(res => {
            return res.getIdToken()
        })
        .then(token => {
            if (!id) throw new Error("id not set");
            return fetch(process.env.REACT_APP_API_ENDPOINT + "oppdretter/" + encodeURIComponent(id), {
                headers: {
                    "Content-type": "application/json",
                    "Authorization": `Bearer ${token.getJwtToken()}`
                }
            })
        })
        .then(res => {
            return res.json()
        })
        .catch(err => { throw err })
    , options);

export const usePutOppdretter = () => {
    const queryClient = useQueryClient()
    const toast = useToast()

    return useMutation((o) => {
        console.log(o)
        return Auth.currentSession()
            .then(res => {
                return res.getIdToken()
            })
            .then(token => {
                return fetch(process.env.REACT_APP_API_ENDPOINT + "oppdretter/" + o.id, {
                    method: "PUT",
                    body: JSON.stringify(prune(o)),
                    headers: {
                        "Content-type": "application/json",
                        "Authorization": `Bearer ${token.getJwtToken()}`
                    }
                })
            })
            .then(async res => {
                return { isOk: res.ok, body: await res.json() }
            })
            .then(v => {
                const { isOk, body } = v
                console.log(v)
                if (!isOk) {
                    throw Error("Feilet grunnet: " + JSON.stringify(body.errors))
                }
            })
    }
        , {
            onMutate: async (oppdretter: Oppdretter) => {
                return { oppdretter }
            },
            onError: (err, variables, context) => {
                toast({ title: "Oppdatering feilet:", description: (err as Error).message, status: "error" })
                console.log(err)
            },
            onSuccess: (data, variables, context) => {
                toast({ title: "Oppdretter Oppdatert!", status: "success" })
                queryClient.invalidateQueries(['getOppdretter', { id: context?.oppdretter.id }])
                queryClient.invalidateQueries('searchOppdretter')
            }
        })
}

export const usePostOppdretter = () => {
    const queryClient = useQueryClient()
    const toast = useToast()

    return useMutation((o) => {
        return Auth.currentSession()
            .then(res => {
                return res.getIdToken()
            })
            .then(token => {
                return fetch(process.env.REACT_APP_API_ENDPOINT + "oppdretter/", {
                    method: "POST",
                    body: JSON.stringify(prune(o)),
                    headers: {
                        "Content-type": "application/json",
                        "Authorization": `Bearer ${token.getJwtToken()}`
                    }
                })
            })
            .then(async res => {
                return { isOk: res.ok, body: await res.json() }
            })
            .then(v => {
                const { isOk, body } = v
                console.log(v)
                if (!isOk) {
                    throw Error("Feilet grunnet: " + JSON.stringify(body))
                }
            })
    }
        , {
            onMutate: async (oppdretter: Oppdretter) => {
                return { oppdretter }
            },
            onError: (err, variables, context) => {
                toast({ title: "Oppretting feilet:", description: (err as Error).message, status: "error" })
                console.log(err)
            },
            onSuccess: (data, variables, context) => {
                toast({ title: "Oppdretter Opprettet!", status: "success" })
                queryClient.invalidateQueries(['getOppdretter', { id: context?.oppdretter.id }])
                queryClient.invalidateQueries('searchOppdretter')
            }
        })
}

export const useDeleteOppdretter = () => {
    const queryClient = useQueryClient()
    const toast = useToast()

    return useMutation((id) => {
        return Auth.currentSession()
            .then(res => {
                return res.getIdToken()
            })
            .then(token => {
                return fetch(process.env.REACT_APP_API_ENDPOINT + "oppdretter/" + encodeURIComponent(id), {
                    method: "DELETE",
                    headers: {
                        "Content-type": "application/json",
                        "Authorization": `Bearer ${token.getJwtToken()}`
                    }
                })
            })
            .then(async res => {
                return { isOk: res.ok }
            })
            .then(v => {
                const { isOk } = v
                if (!isOk) {
                    throw Error("Sletting feilet")
                }
            })
    }
        , {
            onMutate: async (id: string) => {
                return { id }
            },
            onError: (err, variables, context) => {
                toast({ title: "Sletting feilet:", description: (err as Error).message, status: "error" })
                console.log(err)
            },
            onSuccess: (data, variables, context) => {
                toast({ title: "Oppdretter Slettet!", status: "success" })
                queryClient.invalidateQueries(['getOppdretter', { id: context?.id }])
                queryClient.invalidateQueries('searchOppdretter')
            }
        })
}

export const useOppdretterEnums = (options: Options<OppdretterEnums> | undefined = undefined) => useQuery<OppdretterEnums>(['oppdretterEnums'], () =>
    Auth.currentSession()
        .then(res => {
            return res.getIdToken()
        })
        .then(token => {
            return fetch(process.env.REACT_APP_API_ENDPOINT + "oppdretter/property/all", {
                headers: {
                    "Content-type": "application/json",
                    "Authorization": `Bearer ${token.getJwtToken()}`
                }
            })
        })
        .then(res => {
            return res.json()
        })
        .then(json => {
            return json
        })
        .catch(error => { throw error })
    , options);


export const useSearchOppdretter = (filter: OppdretterFilter, sortKey: "oppdr" | "modified_at", page_number: number = 0, page_size: number = 100, options?: Options<OppdretterQueryModel>) => useQuery<OppdretterQueryModel>(['searchOppdretter', { filter, sortKey: sortKey, page_number: page_number, page_size: page_size }], () =>
    Auth.currentSession()
        .then(res => {
            return res.getIdToken()
        })
        .then(token => {
            return fetch(process.env.REACT_APP_API_ENDPOINT + "oppdretter/list/page/" + page_size + "/" + page_number + "?" + new URLSearchParams(pruneAndStringify({
                ...filter,
                "sortKey": sortKey
            })), {
                headers: {
                    "Content-type": "application/json",
                    "Authorization": `Bearer ${token.getJwtToken()}`
                }
            })
        })
        .then(res => {
            return res.json()
        })
        .catch(err => { throw err })
    , options);

export const useKullEnums = (options: Options<KullEnums> | undefined = undefined) => useQuery<KullEnums>(['kullEnums'], () =>
    Auth.currentSession()
        .then(res => {
            return res.getIdToken()
        })
        .then(token => {
            return fetch(process.env.REACT_APP_API_ENDPOINT + "kull/property/all", {
                headers: {
                    "Content-type": "application/json",
                    "Authorization": `Bearer ${token.getJwtToken()}`
                }
            })
        })
        .then(res => {
            return res.json()
        })
        .then(json => {
            return json
        })
        .catch(error => { throw error })
    , options);


export type PostStedFilter = {
    postnr?: string,
    sted?: string,
    land_id?: string
}

export const useGetPoststed = (postnr?: string, options?: Options<Poststed>) => useQuery<Poststed>(['getPoststed', { postnr }], () =>
    Auth.currentSession()
        .then(res => {
            return res.getIdToken()
        })
        .then(token => {
            if (!postnr) throw new Error("postnr not set");
            return fetch(process.env.REACT_APP_API_ENDPOINT + "poststed/" + encodeURIComponent(postnr), {
                headers: {
                    "Content-type": "application/json",
                    "Authorization": `Bearer ${token.getJwtToken()}`
                }
            })
        })
        .then(res => {
            return res.json()
        })
        .then(body => {
            if(body.sted === undefined || body.land_id === undefined || body.fylke_id === undefined ) throw new Error("Postnummer er ikke opprettet")
            return body
        })
        .catch(err => { throw err })
    , options);

export const usePutPoststed = () => {
    const queryClient = useQueryClient()
    const toast = useToast()

    return useMutation((h) => {
        return Auth.currentSession()
            .then(res => {
                return res.getIdToken()
            })
            .then(token => {
                return fetch(process.env.REACT_APP_API_ENDPOINT + "poststed/", {
                    method: "PUT",
                    body: JSON.stringify(pruneAndStringify(h)),
                    headers: {
                        "Content-type": "application/json",
                        "Authorization": `Bearer ${token.getJwtToken()}`
                    }
                })
            })
            .then(async res => {
                return { isOk: res.ok, body: await res.json() }
            })
            .then(v => {
                const { isOk, body } = v
                console.log(v)
                if (!isOk) {
                    throw Error("Feilet grunnet: " + JSON.stringify(body.errors))
                }
            })
    }
        , {
            onMutate: async (poststed: Poststed) => {
                return { poststed }
            },
            onError: (err, variables, context) => {
                toast({ title: "Oppdatering feilet:", description: (err as Error).message, status: "error" })
                console.log(err)
            },
            onSuccess: (data, variables, context) => {
                toast({ title: "Poststed Oppdatert!", status: "success" })
                queryClient.invalidateQueries(['getPoststed', { reg_nr: context?.poststed.postnr }])
                queryClient.invalidateQueries('searchPoststed')
            }
        })
}

export const usePostPoststed = () => {
    const queryClient = useQueryClient()
    const toast = useToast()

    return useMutation((h) => {
        return Auth.currentSession()
            .then(res => {
                return res.getIdToken()
            })
            .then(token => {
                return fetch(process.env.REACT_APP_API_ENDPOINT + "poststed/", {
                    method: "POST",
                    body: JSON.stringify(pruneAndStringify(h)),
                    headers: {
                        "Content-type": "application/json",
                        "Authorization": `Bearer ${token.getJwtToken()}`
                    }
                })
            })
            .then(async res => {
                return { isOk: res.ok, body: await res.json() }
            })
            .then(v => {
                const { isOk, body } = v
                console.log(v)
                if (!isOk) {
                    throw Error("Feilet grunnet: " + JSON.stringify(body))
                }
            })
    }
        , {
            onMutate: async (poststed: Poststed) => {
                return { poststed }
            },
            onError: (err, variables, context) => {
                toast({ title: "Oppretting feilet:", description: (err as Error).message, status: "error" })
                console.log(err)
            },
            onSuccess: (data, variables, context) => {
                toast({ title: "Poststed Opprettet!", status: "success" })
                queryClient.invalidateQueries(['getPoststed', { reg_nr: context?.poststed.postnr }])
                queryClient.invalidateQueries('searchPoststed')
            }
        })
}

export const useDeletePoststed = () => {
    const queryClient = useQueryClient()
    const toast = useToast()

    return useMutation((postnr) => {
        return Auth.currentSession()
            .then(res => {
                return res.getIdToken()
            })
            .then(token => {
                return fetch(process.env.REACT_APP_API_ENDPOINT + "poststed/" + encodeURIComponent(postnr), {
                    method: "DELETE",
                    headers: {
                        "Content-type": "application/json",
                        "Authorization": `Bearer ${token.getJwtToken()}`
                    }
                })
            })
            .then(async res => {
                return { isOk: res.ok }
            })
            .then(v => {
                const { isOk } = v
                if (!isOk) {
                    throw Error("Sletting feilet")
                }
            })
    }
        , {
            onMutate: async (postnr: string) => {
                return { postnr }
            },
            onError: (err, variables, context) => {
                toast({ title: "Sletting feilet:", description: (err as Error).message, status: "error" })
                console.log(err)
            },
            onSuccess: (data, variables, context) => {
                toast({ title: "Poststed Slettet!", status: "success" })
                queryClient.invalidateQueries(['getPoststed', { postnr: context?.postnr }])
                queryClient.invalidateQueries('searchPoststed')
            }
        })
}

export const usePoststedEnums = (options: Options<PoststedEnums> | undefined = undefined) => useQuery<PoststedEnums>(['poststedEnums'], () =>
    Auth.currentSession()
        .then(res => {
            return res.getIdToken()
        })
        .then(token => {
            return fetch(process.env.REACT_APP_API_ENDPOINT + "poststed/property/all", {
                headers: {
                    "Content-type": "application/json",
                    "Authorization": `Bearer ${token.getJwtToken()}`
                }
            })
        })
        .then(res => {
            return res.json()
        })
        .then(json => {
            return json
        })
        .catch(error => { throw error })
    , options);

export const useSearchPoststed = (filter: PostStedFilter, sortKey: "postnr" | "modified_at", page?: number, limit?: number, options?: Options<PoststedQueryModel>) => useQuery<PoststedQueryModel>(['searchPoststed', { filter, sortKey: sortKey, page: page, limit }], () =>
    Auth.currentSession()
        .then(res => {
            return res.getIdToken()
        })
        .then(token => {
            return fetch(process.env.REACT_APP_API_ENDPOINT + "poststed/list?" + new URLSearchParams(pruneAndStringify({
                ...filter,
                "sortKey": sortKey,
                "page": page,
                "limit": limit?.toString()
            })), {
                headers: {
                    "Content-type": "application/json",
                    "Authorization": `Bearer ${token.getJwtToken()}`
                }
            })
        })
        .then(res => {
            return res.json()
        })
        .catch(err => { throw err })
    , options);
import React, { createContext, Reducer, ReducerAction, useReducer, useState } from "react";

export type ShortcutType = {
    keys: string
    description: string
    fn: (...args: any[]) => void
}

export type ShortcutTypeMap = {
    [id: string]: ShortcutType
}

type ShortcutWithContext = { shortcuts: ShortcutTypeMap, context?: string }

export const ShortcutContext = createContext<{ state: ShortcutWithContext, dispatch: React.Dispatch<MyAction> }>({
    state: { shortcuts: {} },
    dispatch: () => null
})

export const ShortcutContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [state, dispatch] = useReducer(shortcutReducer, { shortcuts: {} })

    return (
        <ShortcutContext.Provider value={{ state, dispatch }}>
            {children}
        </ShortcutContext.Provider>
    )
}

type MyAction = { contextId: string, id: string, shortcut: ShortcutType } | "CLEAR"

export const shortcutReducer: Reducer<ShortcutWithContext, MyAction> = (state, action) => {

    if (action === "CLEAR") {
        return { shortcuts: {}, context: undefined }
    }

    if (action.contextId !== state.context) {
        return { shortcuts: { [action.id]: action.shortcut }, context: action.contextId }
    }
    else return { shortcuts: { ...state.shortcuts, [action.id]: action.shortcut }, context: state.context }

}
import { Button, Link, useColorMode, useToast } from '@chakra-ui/react'
import { Table, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/table'
import { useContext, useEffect, useState } from 'react'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import { ShortcutContext } from '../../../context/ShortcutContext';
import { KullFilter, PostStedFilter, useKullEnums, usePoststedEnums, useSearchKull, useSearchPoststed } from '../../../service/service';
import { getValueForEnumCode } from '../../../types/FormEnum';
import { Kull } from '../../../types/Kull';
import { Poststed } from '../../../types/Poststed';
import { useKeyPress } from '../../../utils/hooks/KeyboardShortcutHooks';
import { CenterSpinner } from '../../Globals/CenterSpinner';
import { PostStedSelect } from './PoststedSelect';


export default function PoststedTable() {

    const { colorMode, toggleColorMode } = useColorMode();
    const [filter, setFilter] = useState<PostStedFilter>({ postnr: undefined, sted: undefined, land_id: undefined })
    const eRes = usePoststedEnums()
    const navigate = useNavigate()
    const { state, dispatch } = useContext(ShortcutContext)

    const toast = useToast()

    const { data, isLoading, isError, error } = useSearchPoststed(filter, "modified_at")

    useEffect(() => {
        if (isError) {
            toast({
                title: "Kunne ikke laste poststeder",
                description: "Feil ved lasting av poststeder: " + error,
                status: "error"
            })
        }

    }, [isLoading])

    const multi = () => navigate("/")
    useKeyPress(["F10"], [], multi)
    useEffect(() => {
        dispatch({ contextId: "PoststedTable", id: "9_multi", shortcut: { keys: "F10", description: "MULTI", fn: multi } })
    }, [])

    const opprettPoststed = () => navigate("/poststed")
    useKeyPress(["F2"], [], opprettPoststed)
    useEffect(() => {
        dispatch({ contextId: "PoststedTable", id: "2_opprett_poststed", shortcut: { keys: "F2", description: "OPPRETT NY", fn: opprettPoststed } })
    }, [])

    const tableRows = (data && data.poststeder.length > 0) ? data.poststeder.map((k: Poststed, i: number) =>
        <Tr key={i}>
            <Td>{k.postnr}</Td>
            <Td>{k.sted}</Td>
            <Td>{(eRes.data) ? getValueForEnumCode(eRes.data.land, k.land_id) : k.land_id}</Td>
            <Td>{(eRes.data) ? getValueForEnumCode(eRes.data.fylke, k.fylke_id) : k.fylke_id}</Td>
            <Td>
                <Link as={RouterLink} to={"/poststed/" + encodeURIComponent(k.postnr)}><Button>Se/Oppdater</Button></Link>
            </Td>
        </Tr>
    ) : []

    return (
        <TableContainer>
            <PostStedSelect filter={filter} setFilter={(f) => { let k_filter: PostStedFilter = { ...f }; setFilter({ ...f }) }} height={"10%"} />
            <Table>
                <Thead>
                    <Tr>
                        <Th>Postnr</Th>
                        <Th>Poststed</Th>
                        <Th>Land</Th>
                        <Th>Fylke</Th>
                        <Th>Aksjoner</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {tableRows}
                </Tbody>
            </Table>
            {isLoading && <CenterSpinner />}
        </TableContainer>
    )
}

/* eslint-disable react-hooks/exhaustive-deps */
import { FormControl, useDisclosure } from "@chakra-ui/react";
import { FC, useContext, useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form"
import { useParams, useNavigate } from "react-router-dom";
import { ShortcutContext } from "../../../context/ShortcutContext";
import { useGetHund, usePutHund, usePostHund, useDeleteHund, useGetHundStamtavleHtml } from "../../../service/service";
import { Hund } from "../../../types/Hund";
import { useKeyPress } from "../../../utils/hooks/KeyboardShortcutHooks";
import { CenterSpinner } from "../../Globals/CenterSpinner";
import { ConfirmationDialog } from "../../Globals/ConfirmationDialog";
import { DogMainInfoForm } from "./DogMainInfoForm";
import { DogParentForm } from "./DogParentForm";
import DogTitleForm from "./DogTitleForm";

type Props = {
    duplicate?: boolean
}

const DogForm: FC<Props> = (props) => {

    const { reg_nr } = useParams()
    const [regNr, setRegNr] = useState(reg_nr)
    const { isLoading, isFetching, data } = useGetHund(regNr, { enabled: !!regNr })
    const { mutate: put } = usePutHund()
    const { mutate: post } = usePostHund()
    const { mutate: del, isSuccess: successFullyDeleted } = useDeleteHund()
    const navigate = useNavigate()
    const { dispatch } = useContext(ShortcutContext)
    const { isOpen, onOpen, onClose } = useDisclosure()
    const { download } = useGetHundStamtavleHtml()


    const methods = useForm<Hund>({
        defaultValues: {}
    });

    const multi = () => navigate("/")
    useKeyPress(["F10"], [], multi)
    useEffect(() => {
        dispatch({ contextId: "DogForm", id: "9_multi", shortcut: { keys: "F10", description: "MULTI", fn: multi } })
    }, [])

    const hunderTabellNav = () => navigate("/hunder")
    useKeyPress(["F1"], ["shift"], hunderTabellNav)
    useEffect(() => {
        dispatch({ contextId: "DogForm", id: "1_hunder_tabell", shortcut: { keys: "Sh-F1", description: "TABELL", fn: hunderTabellNav } })
    }, [])

    const reg = () => {
        post(methods.getValues())
        // TODO: Navigate to new on success (And only on success)
    }
    useKeyPress(["F2"], [], reg)
    useEffect(() => {
        dispatch({ contextId: "DogForm", id: "3_lagre", shortcut: { keys: "F2", description: "OK/REG", fn: reg } })
    }, [])

    const hent = () => {
        const regNrValue = methods.getValues("reg_nr")
        if (regNrValue.length > 1 && regNrValue !== regNr) {
            setRegNr(regNrValue)
            // TODO: Navigate to fetched on success (And only on success)
        }
    }
    useKeyPress(["F5"], [], hent)
    useEffect(() => {
        dispatch({ contextId: "DogForm", id: "6_hent_reg_nr", shortcut: { keys: "F5", description: "HENT HUND", fn: hent } })
    }, [])

    const slett = () => {
        const r = methods.getValues("reg_nr")
        del(r)
    }
    useKeyPress(["F7"], [], onOpen)
    useEffect(() => {
        dispatch({ contextId: "DogForm", id: "4_slette", shortcut: { keys: "F7", description: "SLETTE", fn: onOpen } })
    }, [])

    useEffect(() => {
        if (successFullyDeleted) {
            navigate("/hunder")
        }
    }, [successFullyDeleted])

    const endre = () => put(methods.getValues())
    useKeyPress(["F8"], [], endre)
    useEffect(() => {
        dispatch({ contextId: "DogForm", id: "5_endre", shortcut: { keys: "F8", description: "ENDRE", fn: endre } })
    }, [])

    const dupliser = () => {
        navigate("/hund/dupliser/" + encodeURIComponent(methods.getValues("reg_nr")))
    }
    useKeyPress(["d", "D"], ["alt"], dupliser)
    useEffect(() => {
        dispatch({ contextId: "DogForm", id: "8_dupliser", shortcut: { keys: "ALT-D", description: "KOPIER", fn: dupliser } })
    }, [])

    const downloadHtml = () => {
        download(methods.getValues("reg_nr"))
    }
    useKeyPress(["p", "P"], ["ctrl"], downloadHtml)
    useEffect(() => {
        dispatch({ contextId: "DogForm", id: "9_print", shortcut: { keys: "CTRL-P", description: "PRINT STAMTAVLE", fn: downloadHtml } })
    }, [])

    useEffect(() => {
        if (!isLoading && !isFetching) {
            if (!props.duplicate) {
                methods.reset({ ...data, fodt: data?.fodt?.split("T")[0] })
            }
            else {
                methods.reset({
                    fodt: data?.fodt?.split("T")[0],
                    reg_nr: "",
                    navn: "",
                    farge: "",
                    mer: "",
                    fd_land_id: data?.fd_land_id,
                    kullnr: data?.kullnr,
                    kennel: data?.kennel,
                    farens_reg_nr: data?.farens_reg_nr,
                    far: data?.far,
                    morens_reg_nr: data?.morens_reg_nr,
                    mor: data?.mor
                })
            }
        }
    }, [data, isFetching, isLoading, methods, props.duplicate])

    return (
        <FormProvider {...methods}>
            {isFetching && <CenterSpinner />}
            <form>
                <FormControl>
                    <DogMainInfoForm isCreate={props.duplicate || !reg_nr} getFromRegNr={hent} />
                    <DogTitleForm />
                    <DogParentForm marginTop={10} parentname="FAR" />
                    <DogParentForm parentname="MOR" />
                </FormControl>

            </form>
            <ConfirmationDialog onClose={onClose} isOpen={isOpen} onConfirm={slett} title={"Slett hund?"} description={"Dette kan ikke angres"} />
        </FormProvider>
    );
}

export default DogForm;
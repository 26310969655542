import { Box, BoxProps, Button, Flex, FormLabel, Input, Wrap } from '@chakra-ui/react';
import React from 'react';
import { useForm } from 'react-hook-form';
import { PostStedFilter, usePoststedEnums } from '../../../service/service';


type FilterProps = { filter?: PostStedFilter; setFilter: React.Dispatch<React.SetStateAction<PostStedFilter>>; } & BoxProps
export function PostStedSelect(props: FilterProps) {

    const { register, handleSubmit } = useForm<PostStedFilter>({ defaultValues: props.filter });

    const onSubmit = (data: PostStedFilter) => { console.log("Setting!"); props.setFilter(data) };

    return (
        <Box {...props}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Wrap>
                    <Flex>
                        <FormLabel width={20}>Postnr</FormLabel>
                        <Input  {...register("postnr")} width={'24'} />
                    </Flex>
                    <Flex>
                        <FormLabel width={20}>Poststed</FormLabel>
                        <Input  {...register("sted")} />
                    </Flex>
                    <Button type="submit"> Søk </Button>
                </Wrap>
            </form>
        </Box>
    );
}

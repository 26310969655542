import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import { inputAnatomy } from '@chakra-ui/anatomy';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(inputAnatomy.keys);
const baseStyle = definePartsStyle({
  // define the part you're going to style
  field: {
    background: "inherit",
    borderWidth: "1px",
    _disabled: {
      _placeholder: {
        color: "white",
        opacity: 0.7,
        cursor: "auto"
      },
      opacity: 0.7,
      cursor: "auto",
    }
  },
});
export const inputTheme = defineMultiStyleConfig({ baseStyle });

import { Button, Link, useToast } from '@chakra-ui/react'
import { Table, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/table'
import { useContext, useEffect, useState } from 'react'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import { ShortcutContext } from '../../../context/ShortcutContext';
import { OppdretterFilter, useOppdretterEnums, useSearchOppdretter } from '../../../service/service';
import { getValueForEnumCode } from '../../../types/FormEnum';
import { Oppdretter } from '../../../types/Oppdretter';
import { useKeyPress } from '../../../utils/hooks/KeyboardShortcutHooks';
import { CenterSpinner } from '../../Globals/CenterSpinner';
import OppdretterFilterSelect from './OppdretterFilterSelect';


export default function OppdretterTable() {

    const [filter, setFilter] = useState<OppdretterFilter>({ "kennel": undefined })
    const eRes = useOppdretterEnums()
    const navigate = useNavigate()
    const { state, dispatch } = useContext(ShortcutContext)

    const toast = useToast()

    const { data, isLoading, isError, error } = useSearchOppdretter(filter, "modified_at")

    useEffect(() => {
        if (isError) {
            toast({
                title: "Kunne ikke laste oppdrettere",
                description: "Feil ved lasting av oppdrettere: " + error,
                status: "error"
            })
        }

    }, [isLoading])

    const multi = () => navigate("/")
    useKeyPress(["F10"], [], multi)
    useEffect(() => {
        dispatch({ contextId: "OppdretterTable", id: "9_multi", shortcut: { keys: "F10", description: "MULTI", fn: multi } })
    }, [])

    const opprettOppdretter = () => navigate("/oppdretter")
    useKeyPress(["F2"], [], opprettOppdretter)
    useEffect(() => {
        dispatch({ contextId: "OppdretterTable", id: "2_opprett_oppdretter", shortcut: { keys: "F2", description: "OPPRETT NY", fn: opprettOppdretter } })
    }, [])

    const tableRows = (data && data.oppdrettere?.length > 0) ? data.oppdrettere.map((o: Oppdretter, i: number) =>
        <Tr key={i}>
            <Td>{o.kennel}</Td>
            <Td>{o.oppdr}</Td>
            <Td>{(eRes.data) ? getValueForEnumCode(eRes.data.fd_land, o.fd_land_id) : o.fd_land_id}</Td>
            <Td>{o.postnr}</Td>
            <Td>
                <Link as={RouterLink} to={"/oppdretter/" + encodeURIComponent(o.id)}><Button>Se/Oppdater</Button></Link>
            </Td>
        </Tr>
    ) : []

    return (
        <TableContainer>
            < OppdretterFilterSelect oppdretterFilter={filter} setOppdretterFilter={setFilter} />
            <Table>
                <Thead>
                    <Tr>
                        <Th>Kennel</Th>
                        <Th>Navn</Th>
                        <Th>Land</Th>
                        <Th>Postnr</Th>
                        <Th>Aksjoner</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {tableRows}
                </Tbody>
            </Table>
            {isLoading && <CenterSpinner />}
        </TableContainer>
    )
}

/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Flex, List, ListItem, usePrevious } from '@chakra-ui/react'
import { FC, useEffect, useState } from 'react'
import { KullHund } from '../../../types/Kull'
import { HundListItem } from './HundListItem'

type FocusTriggerFieldProps = {
    fn: () => void
}
export const FocusTriggerField: FC<FocusTriggerFieldProps> = ({ fn }) => {

    return (
        <Button onClick={fn} tabIndex={0}>Legg til hund</Button>
    )
}


type Props = {
    hunder?: KullHund[],
    setHunderValueFn: (hunder: KullHund[]) => void
}
export const AddableHundList: FC<Props> = ({ hunder, setHunderValueFn }) => {

    const [hunderState, setHunder] = useState<KullHund[]>([])
    const [hundInputRefs, setHundInputRefs] = useState<{ [index: number]: HTMLInputElement }>({})
    const prevInputRefs = usePrevious(hundInputRefs)
    const [hasAddedNew, setHasAddedNew] = useState(false)

    const updateHundVerdi = (h: KullHund, index: number) => {
        if (hunderState.length > index) {
            if (hunderState[index] !== h) {
                let stateCopy = JSON.parse(JSON.stringify(hunderState))
                stateCopy[index] = h
                setHunder(stateCopy)
            }
        } else {
            setHunder([...hunderState, h])
        }
    }

    const addNewHund = () => {
        setHasAddedNew(true)
        setHunder([...hunderState, {}])
    }

    const setInputRef = (ref: HTMLInputElement, index: number) => {
        if (!hundInputRefs[index]) {
            setHundInputRefs({ ...hundInputRefs, [index]: ref })
        }
    }

    useEffect(() => {
        if (hasAddedNew) {
            if (Object.keys(hundInputRefs || {}).length > Object.keys(prevInputRefs || {}).length) {
                hundInputRefs[hunderState.length - 1].focus()
            }
        }

    }, [hundInputRefs, hasAddedNew])

    useEffect(() => {
        setHunderValueFn(hunderState)
    }, [hunderState])


    useEffect(() => {
        setHunder(hunder || [])
    }, [hunder])

    const listItems = hunderState.map((h, index) => {
        return (
            <ListItem margin={4}>
                <HundListItem hund={h} index={index} updateHundVerdi={(h, index) => updateHundVerdi(h, index)} setInputRef={setInputRef} />
            </ListItem>
        )
    })

    return (
        <Flex flexDir={'column'}>
            <List>
                {listItems}
            </List>
            <FocusTriggerField fn={addNewHund} />
        </Flex>
    )
}

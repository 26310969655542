import { FormLabel, Input, Box, Wrap, Flex } from '@chakra-ui/react'
import { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useHunderEnums } from '../../../service/service';
import { Hund } from '../../../types/Hund';
import { HundEnumSelect } from '../EnumSelect';


const DogTitleForm = () => {

    const { register } = useFormContext<Hund>();
    const { isLoading, isError, data: hunderEnums } = useHunderEnums()

    return (
        <Box border={"2px"}>
            <Wrap overflow={'visible'}>
                <Flex wrap={'wrap'}>
                    <FormLabel width={20}>TITLER</FormLabel>
                    <HundEnumSelect enumArray={hunderEnums?.utd} name="utd_id" />
                    <HundEnumSelect enumArray={hunderEnums?.utd2} name="utd2_id" />
                    <HundEnumSelect enumArray={hunderEnums?.utd3} name="utd3_id" />
                    <HundEnumSelect enumArray={hunderEnums?.utmer} name="utmer_id" />
                    <HundEnumSelect enumArray={hunderEnums?.vin} name="vin_id" />
                </Flex>
            </Wrap>
            <Wrap overflow={'visible'}>
                <Flex wrap={'wrap'}>
                    <FormLabel width={20}>KÅRET</FormLabel>
                    <HundEnumSelect enumArray={hunderEnums?.k} name="k_id" />
                </Flex>
                <Flex wrap={'wrap'}>
                    <FormLabel>ZB</FormLabel>
                    <HundEnumSelect enumArray={hunderEnums?.zb} name="zb_id" />
                </Flex>
                <Flex wrap={'wrap'}>
                    <FormLabel>KKL</FormLabel>
                    <HundEnumSelect enumArray={hunderEnums?.kkl} name="kkl_id" />
                </Flex>
                <Flex wrap={'wrap'}>
                    <FormLabel>BRUKSPREM</FormLabel>
                    <HundEnumSelect enumArray={hunderEnums?.bruks} name="bruks_id" />
                    <HundEnumSelect enumArray={hunderEnums?.bruks2} name="bruks2_id" />
                    <HundEnumSelect enumArray={hunderEnums?.bruks3} name="bruks3_id" />
                    <HundEnumSelect enumArray={hunderEnums?.bruks4} name="bruks4_id" />
                </Flex>
            </Wrap>
            <Wrap overflow={'visible'}>
                <Flex wrap={'wrap'}>
                    <FormLabel width={20}>ANNET</FormLabel>
                    <HundEnumSelect enumArray={hunderEnums?.prem} name="prem_id" />
                    <HundEnumSelect enumArray={hunderEnums?.kval} name="kval_id" />
                    <HundEnumSelect enumArray={hunderEnums?.kval2} name="kval2_id" />
                    <HundEnumSelect enumArray={hunderEnums?.test} name="test_id" />
                    <HundEnumSelect enumArray={hunderEnums?.test} name="test2_id" />
                    <Input placeholder='MER' {...register("mer")} />
                </Flex>
            </Wrap>
        </Box>
    )
}

export default DogTitleForm 
import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure } from "@chakra-ui/react"
import { useRef } from "react"
import { useKeyPress } from "../../utils/hooks/KeyboardShortcutHooks"


type ConfirmationDialogProps = {
    isOpen: boolean,
    onClose: () => void,
    title: string,
    description?: string,
    onConfirm?: () => void
}
export const ConfirmationDialog = (props: ConfirmationDialogProps) => {
    const cancelRef = useRef(null)

    const confirmFn = () => {
        if (props.onConfirm) {
            props.onConfirm()
        }
        props.onClose()
    }

    useKeyPress(["Enter"], [], confirmFn, undefined, !props.isOpen)

    return (
        <>
            <AlertDialog
                isOpen={props.isOpen}
                leastDestructiveRef={cancelRef}
                onClose={props.onClose}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            {props.title}
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            {props.description}
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={props.onClose}>
                                ESC: Avbryt
                            </Button>
                            <Button colorScheme='red' ml={3} onClick={confirmFn}>
                                ENTER: Slett
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    )
}
import { Button, Flex, FormLabel, Input, Text, useDisclosure, Wrap } from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { useForm, SubmitHandler, FormProvider } from "react-hook-form"
import { useParams, useNavigate } from "react-router-dom";
import { ShortcutContext } from "../../../context/ShortcutContext";
import { useGetPoststed, useGetOppdretter, usePutOppdretter, usePostOppdretter, useDeleteOppdretter, useOppdretterEnums, usePoststedEnums } from "../../../service/service";
import { getValueForEnumCode } from "../../../types/FormEnum";
import { Oppdretter } from "../../../types/Oppdretter";
import { Poststed } from "../../../types/Poststed";
import { useKeyPress } from "../../../utils/hooks/KeyboardShortcutHooks";
import { CenterSpinner } from "../../Globals/CenterSpinner";
import { ConfirmationDialog } from "../../Globals/ConfirmationDialog";
import { OppdretterEnumSelect } from "../EnumSelect";


const OppdretterForm = () => {

    const { id } = useParams()
    const [idValue, setIdValue] = useState((id) ? parseInt(id) : undefined)
    const { isLoading, isFetching, data: oppdretterData } = useGetOppdretter(idValue, { enabled: !!idValue })
    const [postNrSearchValue, setPostNrSearchValue] = useState<string>()
    const { data: poststedData, refetch: refetchPostSted } = useGetPoststed(postNrSearchValue, { enabled: false, refetchOnWindowFocus: false })
    const { mutate: put } = usePutOppdretter()
    const { mutate: post } = usePostOppdretter()
    const { data: oppdretterEnums } = useOppdretterEnums()
    const { data: poststedEnums } = usePoststedEnums()
    const navigate = useNavigate()
    const { dispatch } = useContext(ShortcutContext)
    const { mutate: del, isSuccess: successFullyDeleted } = useDeleteOppdretter()
    const { isOpen, onOpen, onClose } = useDisclosure()

    const methods = useForm<Oppdretter & Poststed>()

    const postNr = methods.getValues("postnr")

    const multi = () => navigate("/")
    useKeyPress(["F10"], [], multi)
    useEffect(() => {
        dispatch({ contextId: "OppdretterForm", id: "9_multi", shortcut: { keys: "F10", description: "MULTI", fn: multi } })
    }, [])

    const oppdretterTabellNav = () => navigate("/oppdretter_tabell")
    useKeyPress(["F1"], ["shift"], oppdretterTabellNav)
    useEffect(() => {
        dispatch({ contextId: "OppdretterForm", id: "1_oppdretter_tabell", shortcut: { keys: "Sh-F1", description: "TABELL", fn: oppdretterTabellNav } })
    }, [])

    // TODO: Gir ingen mening å hente fra ID, da den ikke kan settes, og samtidig er betydningsløs.

    // const hent = () => {
    //     const idFieldValue = methods.getValues("id")
    //     if (idFieldValue && idFieldValue !== idValue) {
    //         setIdValue(idFieldValue)
    //     }
    // }
    // useKeyPress(["F5"], [], hent)
    // useEffect(() => {
    //     dispatch({ contextId: "OppdretterForm", id: "6_hent_id", shortcut: { keys: "F5", description: "HENT FRA ID", fn: hent } })
    // }, [])

    const hentPoststed = () => {
        const postNrValue = methods.getValues("postnr")
        if (postNrValue && postNrValue !== postNrSearchValue) {
            setPostNrSearchValue(postNrValue)
        }
    }
    useKeyPress(["P"], ["shift"], hentPoststed)
    useEffect(() => {
        dispatch({ contextId: "OppdretterForm", id: "6_hent_poststed", shortcut: { keys: "Sh-P", description: "FYLL UT POSTSTED", fn: hentPoststed } })
    }, [])

    const reg = () => post(methods.getValues())
    useKeyPress(["F2"], [], reg)
    useEffect(() => {
        dispatch({ contextId: "OppdretterForm", id: "3_lagre", shortcut: { keys: "F2", description: "OK/REG", fn: reg } })
    }, [])

    const endre = () => put(methods.getValues())
    useKeyPress(["F8"], [], endre)
    useEffect(() => {
        dispatch({ contextId: "OppdretterForm", id: "5_endre", shortcut: { keys: "F8", description: "ENDRE", fn: endre } })
    }, [])

    const slett = () => {
        const k = methods.getValues("postnr")
        del(k.toString())
    }
    useKeyPress(["F7"], [], onOpen)
    useEffect(() => {
        dispatch({ contextId: "OppdretterForm", id: "4_slette", shortcut: { keys: "F7", description: "SLETTE", fn: onOpen } })
    }, [])

    useEffect(() => {
        if (successFullyDeleted) {
            // navigate("/oppdretter_tabell")
            navigate("/")
        }
    }, [successFullyDeleted])

    useEffect(() => {
        if (!isLoading && !isFetching) {
            methods.reset(oppdretterData)
        }
    }, [isFetching])

    useEffect(() => {
        if (postNrSearchValue) {
            refetchPostSted()
        }
    }, [postNrSearchValue])

    useEffect(() => {
        hentPoststed()
    }, [postNr])

    // TODO: Fylle ut adresse fra kennel

    return (
        <FormProvider {...methods}>
            {isFetching && <CenterSpinner />}
            <form>
                <Wrap overflow="visible">
                    <Flex>
                        <FormLabel>Kennelnavn</FormLabel>
                        <Input {...methods.register("kennel")} />
                    </Flex>
                    <Flex>
                        <FormLabel>Prefiks/Suffiks</FormLabel>
                        <OppdretterEnumSelect enumArray={oppdretterEnums?.prefiks} name="prefiks_id" />
                    </Flex>
                    <Flex>
                        <FormLabel>OPrefiks</FormLabel>
                        <OppdretterEnumSelect enumArray={oppdretterEnums?.oprefiks} name="oprefiks_id" />
                    </Flex>
                </Wrap>
                <Wrap overflow="visible">
                    <Flex>
                        <FormLabel>Landskode</FormLabel>
                        <OppdretterEnumSelect enumArray={oppdretterEnums?.fd_land} name="fd_land_id" />
                    </Flex>
                </Wrap>
                <Wrap>
                    <Flex>
                        <FormLabel>Navn</FormLabel>
                        <Input {...methods.register("oppdr")} />
                    </Flex>
                </Wrap>
                <Wrap>
                    <Flex>
                        <FormLabel>Adresse</FormLabel>
                        <Input {...methods.register("adresse")} />
                    </Flex>
                </Wrap>
                <Wrap overflow={"visible"}>
                    <Flex>
                        <FormLabel>Postnr.</FormLabel>
                        <Input type="number" {...methods.register("postnr")} />
                    </Flex>
                    <Flex>
                        <FormLabel>Sted</FormLabel>
                        <Input disabled placeholder={poststedData?.sted || ""}></Input>
                    </Flex>
                </Wrap>
                <Wrap overflow={"visible"}>
                    <Flex>
                        <FormLabel>Fylke</FormLabel>
                        <Input disabled placeholder={getValueForEnumCode(poststedEnums?.fylke, poststedData?.fylke_id) || ""}></Input>
                    </Flex>
                    <Flex>
                        <FormLabel>Land</FormLabel>
                        <Input disabled placeholder={getValueForEnumCode(poststedEnums?.land, poststedData?.land_id) || ""}></Input>
                    </Flex>
                </Wrap>
                <Wrap overflow={"visible"}>
                    <Flex>
                        <FormLabel>Privat</FormLabel>
                        <Input type={"tel"} {...methods.register("tlf")} />
                    </Flex>
                    <Flex>
                        <FormLabel>Arbeid</FormLabel>
                        <Input type={"tel"} {...methods.register("tlfa")} />
                    </Flex>
                    <Flex>
                        <FormLabel>Mobil</FormLabel>
                        <Input type={"tel"} {...methods.register("tlfm")} />
                    </Flex>
                </Wrap>
            </form>
            <ConfirmationDialog onClose={onClose} isOpen={isOpen} onConfirm={slett} title={"Slett oppdretter?"} description={"Dette kan ikke angres"} />
        </FormProvider>
    );
}

export default OppdretterForm;
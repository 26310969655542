/* eslint-disable react-hooks/exhaustive-deps */
import { Flex, FormLabel, Input, useDisclosure, Wrap } from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form"
import { useParams, useNavigate } from "react-router-dom";
import { ShortcutContext } from "../../../context/ShortcutContext";
import { usePoststedEnums, useGetPoststed, usePutPoststed, usePostPoststed, useDeletePoststed } from "../../../service/service";
import { Poststed } from "../../../types/Poststed";
import { useKeyPress } from "../../../utils/hooks/KeyboardShortcutHooks";
import { CenterSpinner } from "../../Globals/CenterSpinner";
import { ConfirmationDialog } from "../../Globals/ConfirmationDialog";
import { PoststedEnumSelect } from "../EnumSelect";
import SearchableInput from "../SearchableInput";


const PoststedForm = () => {

    const { post_nr } = useParams()
    const [postNr, setPostNr] = useState(post_nr)
    const { isLoading, isFetching, data: poststedData } = useGetPoststed(postNr, { enabled: !!postNr })
    const { data: poststedEnums } = usePoststedEnums()
    const { mutate: put } = usePutPoststed()
    const { mutate: post } = usePostPoststed()
    const navigate = useNavigate()
    const { dispatch } = useContext(ShortcutContext)
    const { mutate: del, isSuccess: successFullyDeleted } = useDeletePoststed()
    const { isOpen, onOpen, onClose } = useDisclosure()

    const methods = useForm<Poststed>()

    const multi = () => navigate("/")
    useKeyPress(["F10"], [], multi)
    useEffect(() => {
        dispatch({ contextId: "PoststedForm", id: "9_multi", shortcut: { keys: "F10", description: "MULTI", fn: multi } })
    }, [])

    // TODO: Finnes ingen poststed-tabell, da list-endepunkt ikke eksisterer
    // const poststedTabellNav = () => navigate("/") //navigate("/poststed_tabell")
    // useKeyPress(["F1"], ["shift"], poststedTabellNav)
    // useEffect(() => {
    //     dispatch({ contextId: "PoststedForm", id: "1_poststed_tabell", shortcut: { keys: "Sh-F1", description: "TABELL", fn: poststedTabellNav } })
    // }, [])

    const hent = () => {
        const postNrValue = methods.getValues("postnr")
        if (postNrValue.length > 1 && postNrValue !== postNr) {
            setPostNr(postNrValue)
        }
    }
    useKeyPress(["F5"], [], hent)
    useEffect(() => {
        dispatch({ contextId: "PoststedForm", id: "6_hent_post_nr", shortcut: { keys: "F5", description: "HENT FRA POSTNR", fn: hent } })
    }, [])

    const reg = () => post(methods.getValues())
    useKeyPress(["F2"], [], reg)
    useEffect(() => {
        dispatch({ contextId: "PoststedForm", id: "3_lagre", shortcut: { keys: "F2", description: "OK/REG", fn: reg } })
    }, [])

    // TODO: PUT-endepunktet er enten ikke-eksisterende eller feildokumentert
    const endre = () => put(methods.getValues())
    useKeyPress(["F8"], [], endre)
    useEffect(() => {
        dispatch({ contextId: "PoststedForm", id: "5_endre", shortcut: { keys: "F8", description: "ENDRE", fn: endre } })
    }, [])

    const slett = () => {
        const k = methods.getValues("postnr")
        del(k.toString())
    }
    useKeyPress(["F7"], [], onOpen)
    useEffect(() => {
        dispatch({ contextId: "PoststedForm", id: "4_slette", shortcut: { keys: "F7", description: "SLETTE", fn: onOpen } })
    }, [])

    const poststedTabellNav = () => navigate("/poststeder")
    useKeyPress(["F1"], ["shift"], poststedTabellNav)
    useEffect(() => {
        dispatch({ contextId: "PoststedForm", id: "1_poststed_tabell", shortcut: { keys: "Sh-F1", description: "TABELL", fn: poststedTabellNav } })
    }, [])

    useEffect(() => {
        if (successFullyDeleted) {
            navigate("/poststed")
        }
    }, [successFullyDeleted])

    useEffect(() => {
        if (!isLoading && !isFetching) {
            methods.reset(poststedData)
        }
    }, [isFetching])

    return (
        <FormProvider {...methods}>
            {isFetching && <CenterSpinner />}
            <form>
                <Wrap>
                    <Flex>
                        <FormLabel>Postnummer</FormLabel>
                        <SearchableInput useFormRegister={methods.register("postnr")} onClick={hent} />
                    </Flex>
                    <Flex>
                        <FormLabel>Poststed</FormLabel>
                        <Input {...methods.register("sted")} />
                    </Flex>
                </Wrap>
                <Wrap overflow={"visible"}>
                    <Flex>
                        <FormLabel>Land</FormLabel>
                        <PoststedEnumSelect enumArray={poststedEnums?.land} name="land_id" />
                    </Flex>
                    <Flex>
                        <FormLabel>Fylke</FormLabel>
                        <PoststedEnumSelect enumArray={poststedEnums?.fylke} name="fylke_id" />
                    </Flex>
                </Wrap>
            </form>
            <ConfirmationDialog onClose={onClose} isOpen={isOpen} onConfirm={slett} title={"Slett poststed?"} description={"Dette kan ikke angres"} />
        </FormProvider>
    );
}

export default PoststedForm;
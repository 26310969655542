import { Card, Checkbox, Flex, FormLabel, Input, Text, Wrap } from '@chakra-ui/react'
import { Select } from 'chakra-react-select'
import { FC, useState } from 'react'
import { useKullEnums } from '../../../service/service'
import { getValueForEnumCode, selectOptionsFromEnumArray } from '../../../types/FormEnum'
import { KullHund } from '../../../types/Kull'

type Props = {
    hund?: KullHund
    updateHundVerdi: (h: KullHund, index: number) => void
    index: number
    setInputRef: (ref: HTMLInputElement, index: number) => void
}
export const HundListItem: FC<Props> = ({ hund, updateHundVerdi, index, setInputRef }) => {

    const [hundState, setHund] = useState(hund || {})
    const { data: kullEnums } = useKullEnums()

    const updateFieldValue = (v: KullHund) => {
        setHund({ ...hundState, ...v })
    }

    const onBlur = () => {
        updateHundVerdi(hundState, index)
    }

    return (
        <Card onBlur={onBlur} padding={2}>
            <Text>Hund {index + 1}:</Text>
            <form>
                <Wrap overflow={"visible"}>
                    <Flex>
                        <FormLabel>Navn</FormLabel>
                        <Input id={'hund_id_idx_' + index} name='navn' defaultValue={hund?.navn} onChange={(e) => updateFieldValue({ navn: e.target.value })}
                            ref={(el) => { if (el) setInputRef(el, index) }} />
                    </Flex>
                    <Flex>
                        <FormLabel>Kjønn</FormLabel>
                        <Select options={selectOptionsFromEnumArray(kullEnums?.kjonn)} name="kjonn_id" onChange={(e) => updateFieldValue({ kjonn_id: e?.value })} />
                    </Flex>
                    <Flex>
                        <FormLabel>Kåret</FormLabel>
                        <Checkbox name='kaaret' defaultChecked={hund?.kaaret} onChange={(e) => updateFieldValue({ kaaret: e?.target.checked })} />
                    </Flex>
                    <Flex>
                        <FormLabel>Meritter</FormLabel>
                        <Select options={selectOptionsFromEnumArray(kullEnums?.meritter)} name="meritter" onChange={(e) => updateFieldValue({ meritter_id: e?.value })} />
                    </Flex>
                    <Flex>
                        <FormLabel>Hd</FormLabel>
                        <Select options={selectOptionsFromEnumArray(kullEnums?.hd)} name="hd" onChange={(e) => updateFieldValue({ hd_id: e?.value })} />
                    </Flex>
                </Wrap>
                <Wrap>
                    <Flex>
                        <FormLabel>Reg.nr.</FormLabel>
                        <Input name='reg_nr' defaultValue={hund?.reg_nr} onChange={(e) => updateFieldValue({ reg_nr: e?.target.value })} />
                    </Flex>
                    <Flex>
                        <FormLabel>=</FormLabel>
                        <Text>{hundState?.kaaret ? "+" : ""}{hundState.navn}/ {getValueForEnumCode(kullEnums?.meritter, hundState.meritter_id)}</Text>
                    </Flex>
                </Wrap>
            </form>
        </Card>
    )
}

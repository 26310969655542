import { Flex, Stack, VStack } from '@chakra-ui/react'
import React, { useContext, useEffect } from 'react'
import { ShortcutContext } from '../../context/ShortcutContext'
import { NavigationOption } from './NavigationOption'

export const LandingPage = () => {

    const { state, dispatch } = useContext(ShortcutContext)

    useEffect(() => {
        dispatch("CLEAR")
    }, [])


    return (
        <VStack>
            <NavigationOption description='Hundetabell' path='/hunder' id="1" />
            <NavigationOption description='Opprett hund' path='/hund' id="2" />
            <NavigationOption description='Kulltabell' path='/kull_tabell' id="3" />
            <NavigationOption description='Opprett kull' path='/kull' id="4" />
            <NavigationOption description='Oppdrettertabell' path='/oppdretter_tabell' id="5" />
            <NavigationOption description='Opprett oppdretter' path='/oppdretter' id="6" />
            <NavigationOption description='Opprett/Endre poststed' path='/poststed' id="7" />
            <NavigationOption description='Poststedtabell' path='/poststeder' id="8" />
        </VStack>
    )
}

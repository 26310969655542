import { Box, Card, CardBody, Divider, HStack, LinkBox, Text } from '@chakra-ui/react'
import React, { Key } from 'react'
import { useNavigate } from 'react-router-dom'
import { useKeyPress } from '../../utils/hooks/KeyboardShortcutHooks'

type Props = {
    description: string,
    path: string,
    id: string,
    incomplete?: boolean
}
export const NavigationOption = (props: Props) => {

    const navigate = useNavigate()
    useKeyPress(["F" + props.id], [], () => { !props.incomplete && navigate(props.path) })

    return (
        <LinkBox width={"100%"} display="inherit" borderColor={"gray"} borderWidth={1} borderRadius={10}>
            <Box as='a' href={props.path} width={"100%"} height={"20"}>
                <HStack height={"100%"} padding={3}>
                    <Text>{"F" + props.id}</Text>
                    <Divider orientation='vertical' stroke="5px white" />
                    <Text>{props.description}</Text>
                    {props.incomplete && <Text color={"red"}>Ikke ferdig</Text>}
                </HStack>
            </Box>
        </LinkBox>
    )
}
